import { Action } from '@ngrx/store';

export enum AssemblerActionTypes {
    ASSEMBLER_EDIT = '[ASSEMBLER] AssemblerEdit',
    ASSEMBLER_EDITED = '[ASSEMBLER] AssemblerEdited',
    ASSEMBLER_ADD = '[ASSEMBLER] AssemblerAdd',
    ASSEMBLER_ADDED = '[ASSEMBLER] AssemblerAdded',
    ASSEMBLER_DELETE = '[ASSEMBLER] AssemblerDelete',
    ASSEMBLER_DELETED = '[ASSEMBLER] AssemblerDeleted',
    ASSEMBLER_GET_PAGE = '[ASSEMBLER] AssemblerGetPage',
    ASSEMBLER_PAGE_LOADED = '[ASSEMBLER] AssemblerPageLoaded',
    ASSEMBLER_GET_ALL = '[ASSEMBLER] AssemblerGetAll',
    ASSEMBLER_ALL_LOADED = '[ASSEMBLER] AssemblerAllloaded'
}

export class AssemblerAdd implements Action {
    readonly type = AssemblerActionTypes.ASSEMBLER_ADD;
    constructor(public payload: any) {}
}

export class AssemblerEdit implements Action {
    readonly type = AssemblerActionTypes.ASSEMBLER_EDIT;
    constructor(public payload: any) {}
}

export class AssemblerGetPage implements Action {
    readonly type = AssemblerActionTypes.ASSEMBLER_GET_PAGE;
    constructor(public payload: any) {}
}

export class AssemblerPageLoaded implements Action {
    readonly type = AssemblerActionTypes.ASSEMBLER_PAGE_LOADED;
    constructor(public payload: any) {}
}

export class AssemblerAdded implements Action {
    readonly type = AssemblerActionTypes.ASSEMBLER_ADDED;
    constructor(public payload: any) {}
}

export class AssemblerEdited implements Action {
    readonly type = AssemblerActionTypes.ASSEMBLER_EDITED;
    constructor(public payload: any) {}
}

export class AssemblerDelete implements Action {
    readonly type = AssemblerActionTypes.ASSEMBLER_DELETE;
    constructor(public payload: any) {}
}

export class AssemblerDeleted implements Action {
    readonly type = AssemblerActionTypes.ASSEMBLER_DELETED;
    constructor(public payload: any) {}
}

export class AssemblerGetAll implements Action {
    readonly type = AssemblerActionTypes.ASSEMBLER_GET_ALL;
    constructor(public payload: any) {}
}

export class AssemblerAllLoaded implements Action {
    readonly type = AssemblerActionTypes.ASSEMBLER_ALL_LOADED;
    constructor(public payload: any) {}
}

export type AssemblerActions =
                | AssemblerAdd
                | AssemblerEdit
                | AssemblerGetPage
                | AssemblerPageLoaded
                | AssemblerAdded
                | AssemblerDeleted
                | AssemblerDelete 
                | AssemblerEdited
                | AssemblerGetAll
                | AssemblerAllLoaded;