import { BaseModel } from '../../_base/crud';

export class BirdStockMovement extends BaseModel {
    id: number;
    create_uid: number;
    write_uid: number;
    write_date: any;
    create_date: any;
    company_id: number;
    pavilion_src_id: number;
    pavilion_src_name: string;
    pavilion_dst_id: number;
    pavilion_dst_name: string;
    floor_id: number;
    floor_name: string;
    generation_id: number;
    generation_name: string;
    generation_src_id: number;
    generation_src_name: string;
    bird_id: number;
    innitial_load_date: any;
    amount: number;
    breeding_id: number;
    breeding_age: number;
    breeding_amount: number;
    bird_age: number;
    bird_amount: number;
    bird_type: string;
    bird_type_id: number;
    
    clear(){
        this.id                     = undefined;
        this.create_uid             = undefined;
        this.write_date             = undefined;
        this.write_uid              = undefined;
        this.create_date            = undefined;
        this.company_id             = undefined;
        this.pavilion_src_id        = undefined;
        this.pavilion_src_name      = "";
        this.pavilion_dst_id        = undefined;
        this.pavilion_dst_name      = "";
        this.floor_id               = undefined;
        this.floor_name             = "";
        this.generation_id          = undefined;
        this.generation_name        = "";
        this.bird_id                = undefined;
        this.innitial_load_date     = undefined;
        this.amount                 = undefined;
        this.breeding_id            = undefined;
        this.breeding_age           = undefined;
        this.breeding_amount        = undefined;
        this.bird_age               = undefined;
        this.bird_amount            = undefined;
        this.bird_type              = "";
        this.bird_type_id           = undefined;
        this.generation_src_id      = undefined;
        this.generation_src_name    = "";
    }
}