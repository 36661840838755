// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { GrainTypeState } from '../_reducers/grain-stock.reducers';
import { GrainType } from '../_models/grain-stock.model';

export const selectGrainTypesState = createFeatureSelector<GrainTypeState>('grainTypes');

export const selectGrainTypeById = (grainTypeId: number) => createSelector(
    selectGrainTypesState,
    grainTypesState => grainTypesState.entities[grainTypeId]
);

export const selectGrainTypesPageLoading = createSelector(
    selectGrainTypesState,
    grainTypesState => grainTypesState.listLoading
);

// export const selectProductsActionLoading = createSelector(
//     selectGrainTypesState,
//     customersState => customersState.actionsloading
// );

export const selectGrainTypesPageLastQuery = createSelector(
    selectGrainTypesState,
    grainTypesState => grainTypesState.lastQuery
);

export const selectLastCreatedGrainTypeId = createSelector(
    selectGrainTypesState,
    grainTypesState => grainTypesState.lastCreatedGrainTypeId
);

export const selectGrainTypesInitWaitingMessage = createSelector(
    selectGrainTypesState,
    grainTypesState => grainTypesState.showInitWaitingMessage
);

export const selectGrainTypesInStore = createSelector(
    selectGrainTypesState,
    grainTypesState => {
        const items: GrainType[] = [];
        each(grainTypesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: GrainType[] = httpExtension.sortArray(items, grainTypesState.lastQuery.sortField, grainTypesState.lastQuery.sortOrder);
        return new QueryResultsModel(result, grainTypesState.totalCount, '');
    }
);

export const selectHasGrainTypesInStore = createSelector(
    selectGrainTypesInStore,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);
