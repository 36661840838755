//export const BASE_URL = "http://localhost:3000"                //INTERNAL DEV
//export const BASE_URL = "http://rts.ir4hub.cl:30032"                //PROD1
export const BASE_URL = "https://rts.ir4hub.cl:30032"                //PROD2
//export const BASE_URL = "http://192.168.2.15:3001"                //LAB

export const API_USERS_URL = BASE_URL+'/user';
export const API_PAVILIONS_URL = BASE_URL+'/pavilion';
export const API_SILOS_URL = BASE_URL + '/silo'
export const API_ASSEMBLER_URL = BASE_URL + '/assembler'
export const API_SHIFT_URL = BASE_URL + '/shift'
export const API_BIRDS_URL = BASE_URL + '/bird'
export const API_GRAIN_TYPE_URL = BASE_URL + '/grain-type'
export const API_ALARMS_URL = BASE_URL+'/alarms';
export const API_PERMISSION_URL = 'api/permissions';
export const API_ROLES_URL = 'api/roles';
export const API_PRODUCTION_URL = BASE_URL + '/production';