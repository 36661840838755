import { Component, OnInit } from '@angular/core';
import { data1Mac1 } from "../../../../../../core/production/_models/chartsData";

@Component({
  selector: 'kt-mac1-chart1',
  templateUrl: './mac1-chart1.component.html',
  styleUrls: ['./mac1-chart1.component.scss']
})
export class Mac1Chart1Component implements OnInit {

  //Charts
  data1Mac1: any[];
  view: any[];
  legend: boolean = false;
  legendPosition: string = "center";
  showAxis: boolean = false;

  colorScheme = {
    domain: ["#5AA454"]
  };

  constructor() {

   }

  ngOnInit() {
            //Charts
            Object.assign(this, { data1Mac1 });
  }

    //Charts
    onSelect(data): void {
      console.log("Item clicked", JSON.parse(JSON.stringify(data)));
    }
  
    onActivate(data): void {
      console.log("Activate", JSON.parse(JSON.stringify(data)));
    }
  
    onDeactivate(data): void {
      console.log("Deactivate", JSON.parse(JSON.stringify(data)));
    }
  

}
