import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-realtime-mac1-chart2',
  templateUrl: './realtime-mac1-chart2.component.html',
  styleUrls: ['./realtime-mac1-chart2.component.scss']
})
export class RealtimeMac1Chart2Component implements OnInit {

  public canvasWidth = 250
  public needleValue = 65
  public centralLabel = `${this.needleValue}`
/*   public bottomLabel = `% Velocidad Máxima`;
  public name = "Velocidad Instantánea" */
  public options = {
      hasNeedle: true,
      needleColor: '#000000',
      needleUpdateSpeed: 1000,
      arcColors: [' #585858', '#f6954b'],
      arcDelimiters: [30],
      rangeLabel: ['0', '100'],
      needleStartValue: 50,
      rangeLabelFontSize: 13,
  }
  constructor() { }

  ngOnInit() {
    
  }

}
