import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../auth';
import {BASE_URL, API_PRODUCTION_URL} from '../../connection.data';
import {QueryParamsModel, QueryResultsModel} from '../../_base/crud';
import {HttpClientModule} from '@angular/common/http';
//import { Pavilion, Pavilioner } from '../_models/pavilion.model';
//const API_USERS_URL = 'api/users';
//const API_PERMISSION_URL = 'api/permissions';
//const API_ROLES_URL = 'api/roles';

//const BASE_URL = "http://localhost:3000"                //INTERNAL DEV
//const BASE_URL = "http://ir4hub.cl:45016"                //LAB DEV

@Injectable()
export class PavilionHistoryService{

    constructor(private http: HttpClient){

    }
    get_history_pavilion_harvest_day(pavilion_id,starting_date,ending_date): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/history_pavilion_harvest_day?pavilion_id=${pavilion_id}&starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_harvest_week(pavilion_id,starting_date,ending_date): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/history_pavilion_harvest_week?pavilion_id=${pavilion_id}&starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_harvest_generation(pavilion_id,starting_date,ending_date,generation_id): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/history_pavilion_harvest_generation?pavilion_id=${pavilion_id}&starting_date=${starting_date}&ending_date=${ending_date}&generation_id=${generation_id}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_acc_harvest_day(pavilion_id,starting_date,ending_date): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/history_pavilion_acc_harvest_day?pavilion_id=${pavilion_id}&starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_acc_harvest_week(pavilion_id,starting_date,ending_date): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/history_pavilion_acc_harvest_week?pavilion_id=${pavilion_id}&starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_acc_harvest_generation(pavilion_id,starting_date,ending_date,generation_id): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/history_pavilion_acc_harvest_generation?pavilion_id=${pavilion_id}&starting_date=${starting_date}&ending_date=${ending_date}&generation_id=${generation_id}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_nourishment_day(pavilion_id,starting_date,ending_date): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/history_pavilion_nourishment_day?pavilion_id=${pavilion_id}&starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_nourishment_week(pavilion_id,starting_date,ending_date): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/history_pavilion_nourishment_week?pavilion_id=${pavilion_id}&starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_nourishment_generation(pavilion_id,starting_date,ending_date,generation_id): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/history_pavilion_nourishment_generation?pavilion_id=${pavilion_id}&starting_date=${starting_date}&ending_date=${ending_date}&generation_id=${generation_id}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_temperature_day(pavilion_id,starting_date,ending_date): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/history_pavilion_temperature_day?pavilion_id=${pavilion_id}&starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_temperature_week(pavilion_id,starting_date,ending_date): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/history_pavilion_temperature_week?pavilion_id=${pavilion_id}&starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_temperature_generation(pavilion_id,starting_date,ending_date,generation_id): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/history_pavilion_temperature_generation?pavilion_id=${pavilion_id}&starting_date=${starting_date}&ending_date=${ending_date}&generation_id=${generation_id}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_water_day(pavilion_id,starting_date,ending_date): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/history_pavilion_water_day?pavilion_id=${pavilion_id}&starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_water_week(pavilion_id,starting_date,ending_date): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/history_pavilion_water_week?pavilion_id=${pavilion_id}&starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_water_generation(pavilion_id,starting_date,ending_date,generation_id): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/history_pavilion_water_generation?pavilion_id=${pavilion_id}&starting_date=${starting_date}&ending_date=${ending_date}&generation_id=${generation_id}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_deads_day(pavilion_id,starting_date,ending_date): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/history_pavilion_deads_day?pavilion_id=${pavilion_id}&starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_deads_week(pavilion_id,starting_date,ending_date): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/history_pavilion_deads_week?pavilion_id=${pavilion_id}&starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_deads_generation(pavilion_id,starting_date,ending_date,generation_id): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/history_pavilion_deads_generation?pavilion_id=${pavilion_id}&starting_date=${starting_date}&ending_date=${ending_date}&generation_id=${generation_id}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_production_realtime_total_bird_by_type(): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/production_realtime_total_bird_by_type`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_production_realtime_total_bird_by_pavilion(): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/production_realtime_total_bird_by_pavilion`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }

    get_assembler_totals(shift_type,from_date,end_date): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/get_assembler_totals?shift_type=${shift_type}&from_date=${from_date}&end_date=${end_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }

    /*getAll():Observable<Pavilion[]>{       
        const url = `${API_PAVILIONS_URL}/all`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_production_realtime_total_bird_by_pavilion(): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/production_realtime_total_bird_by_pavilion`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }*/
}