import { BaseModel } from '../../_base/crud';

export class Silo extends BaseModel {
    id: number;
    create_uid: number;
    write_uid: number;
    write_date: any;
    create_date: any;
    name: string;
    capacity: number;
    p_reorder: number; //Porcentaje para volver a ordenar !
    grain_type_id: number;
    grain_type: string;
    company_id: number;
    clear(){
        this.id             = undefined;
        this.create_uid     = undefined;
        this.write_date     = undefined;
        this.write_uid      = undefined;
        this.create_date    = undefined;
        this.name           = "";
        this.capacity       = undefined;
        this.p_reorder      = undefined;
        this.grain_type_id  = undefined;
        this.grain_type     = "";
        this.company_id     = undefined;
    }
}