// Angular
import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { AppState } from '../../../../../core/reducers';
import { each, find, remove } from 'lodash';
// Layout
import { SubheaderService, LayoutConfigService } from '../../../../../core/_base/layout';
import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud';
import * as CryptoJS from 'crypto-js';

// Services and Models
import {
	User,
	UserUpdated,
	//Address,
	//SocialNetworks,
	//selectHasUsersInStore,
	selectUserById,
	UserOnServerCreated,
	selectLastCreatedUserId,
	selectUsersActionLoading,
	Profiles,
	selectAllProfiles,
	AuthService,
	ProfilesPageRequested,
} from '../../../../../core/auth';
import { selectAuthState } from '../../../../../core/auth/_selectors/auth.selectors';
import { AllProfilesRequested } from '../../../../../core/auth/_actions/profile.actions';

@Component({
	selector: 'kt-user-edit',
	templateUrl: './user-edit.component.html',
	styleUrls: ['./user-edit.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserEditComponent implements OnInit, OnDestroy {
	@ViewChild('wizard', {static: true}) el: ElementRef;

	// Public properties
	user: User;
	private currentUser: User = new User();
	userId$: Observable<number>;
	oldUser: User;
	selectedTab = 0;
	loading$: Observable<boolean>;
	//rolesSubject = new BehaviorSubject<number[]>([]);
	// profileSubject = new BehaviorSubject<number[]>([]);
	//addressSubject = new BehaviorSubject<Address>(new Address());
	//soicialNetworksSubject = new BehaviorSubject<SocialNetworks>(new SocialNetworks());
	userForm: FormGroup;
	hasFormErrors = false;
	// Private properties
	private subscriptions: Subscription[] = [];
	profilesResult: Profiles[];
  	selectedProfile: Profiles = new Profiles();

	@Input() profilesSubject: BehaviorSubject<number[]>;
	allUserProfiles$: Observable<Profiles[]>;
	allProfiles: Profiles[] = [];
	//unassignedProfiles: Profiles[] = [];
	assignedProfiles: Profiles[] = [];
	profileIdForAdding: number;
	unassignedProfiles;

	uid;
	partner_id;
	group_id;
	
	private headerMargin: number;

	/**
	 * Component constructor
	 *
	 * @param activatedRoute: ActivatedRoute
	 * @param router: Router
	 * @param userFB: FormBuilder
	 * @param subheaderService: SubheaderService
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param store: Store<AppState>
	 * @param layoutConfigService: LayoutConfigService
	 */
	constructor(private activatedRoute: ActivatedRoute,
		           private router: Router,
		           private userFB: FormBuilder,
		           private subheaderService: SubheaderService,
		           private layoutUtilsService: LayoutUtilsService,
				   private store: Store<AppState>,
				   private auth: AuthService,
				   private cdr: ChangeDetectorRef,
				   //private layoutConfigService: LayoutConfigService
				   ) { 
					this.group_id =  localStorage.getItem('group_id');
					this.uid = localStorage.getItem('uid');
					this.partner_id = localStorage.getItem('partner_id');
				   }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.currentUser.clear();

		const authSubscription = this.store.pipe(
			select(selectAuthState),
		).subscribe((auth) => {
			// console.log("auth state: "+JSON.stringify(auth));
			if(auth.isUserLoaded)
				this.currentUser = auth.user;
			else
				console.log("shift edit component: usuario no logueado");
		});
		this.subscriptions.push(authSubscription);

		
		const profiles$ = this.store.pipe(
			select(selectAllProfiles)
		);

		const profilesSub = profiles$.subscribe(
			(profiles)=>{
				this.profilesResult = profiles;
				console.log("profilesSub: "+JSON.stringify(profiles));
				if(profiles && this.userForm && profiles.length>0){
					this.userForm.get("group").patchValue(profiles[0].name);
					this.selectProfile(profiles[0]);
				}
				this.cdr.detectChanges();
			}
		)
		this.subscriptions.push(profilesSub);
		
		//console.log('ENTERING ngOnInit');
		this.loading$ = this.store.pipe(select(selectUsersActionLoading));

		const routeSubscription =  this.activatedRoute.params.subscribe(params => {
			//console.table(params);
			const id = params.id;
			if (id && id > 0) {
				this.store.pipe(select(selectUserById(id))).subscribe(res => {
					console.table("res: "+JSON.stringify(res));
					if (res) {
						this.user = new User();
						this.user.clear();
						this.user = Object.assign(this.user, res);
						this.oldUser = Object.assign({}, this.user);
						this.initUser();
					}
				});
			} else {
				this.user = new User();
				this.user.clear();
				// this.profileSubject.next(this.user.profiles);
				this.oldUser = Object.assign({}, this.user);
				this.initUser();
			}
		});
		this.subscriptions.push(routeSubscription);
		this.prepareProfile();

		// sticky portlet header
		window.onload = () => {
			const style = getComputedStyle(document.getElementById('kt_header'));
			this.headerMargin = parseInt(style.height, 0);
		};
		const wizard = new KTWizard(this.el.nativeElement, {
			startStep: 1
		});

		// Validation before going to next page
		wizard.on('beforeNext', (wizardObj) => {
			// https://angular.io/guide/forms
			// https://angular.io/guide/form-validation

			// validate the form and use below function to stop the wizard's step
			// wizardObj.stop();
		});

		// Change event
		wizard.on('change', () => {
			setTimeout(() => {
				KTUtil.scrollTop();
			}, 500);
		});

		this.loadProfiles();
	}

	prepareProfile(){
		this.auth.getAllProfiles().subscribe(res => {
			//console.table(res)
			this.unassignedProfiles = res;		
		});
	}

	loadProfiles(){
		this.store.dispatch(new AllProfilesRequested());
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sb => sb.unsubscribe());
	}

	/**
	 * Init user
	 */
	initUser() {
		this.createForm();
		if (!this.user.id) {
			this.subheaderService.setTitle('Crear nuevo usuario');
			this.subheaderService.setBreadcrumbs([
				{ title: 'Administración de usuarios', page: `/user-management` },
				{ title: 'Usuarios',  page: `/user-management/users` },
				{ title: 'Crear usuario', page: `/user-management/users/add` }
			]);
			return;
		}
		this.subheaderService.setTitle('Editar usuarios');
		this.subheaderService.setBreadcrumbs([
			{ title: 'Administrador de usuarios', page: `/user-management` },
			{ title: 'Usuario',  page: `/user-management/users` },
			{ title: 'Editar usuarios', page: `/user-management/users/edit`, queryParams: { id: this.user.id } }
		]);
	}

	/**
	 * Create form
	 */
	createForm() {
		this.userForm = this.userFB.group({
			username: [this.user.login, [Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),Validators.required]],
			name: [this.user.name, Validators.required],
			email: [this.user.email, [Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),Validators.required]],
			mobile: [this.user.mobile, Validators.required],
			password: [''],
			group : [this.user.group_name, Validators.required],
		});

		const controls = this.userForm.controls;
		const nameSub = controls.name.valueChanges.subscribe(x=>{
			this.user.name = x;
		});
		const usernameSub = controls.username.valueChanges.subscribe(x=>{
			this.user.login = x;
		});
		const emailSub = controls.email.valueChanges.subscribe(x=>{
			this.user.email = x;
		});
		const groupSub = controls.group.valueChanges.subscribe(x=>{
			this.user.group_name = x;
		});
		const mobileSub = controls.mobile.valueChanges.subscribe(x=>{
			this.user.mobile = x;
		});
		[nameSub, usernameSub, emailSub, groupSub, mobileSub].forEach(sub=>this.subscriptions.push(sub));
		this.cdr.detectChanges();
	}

	/**
	 * Redirect to list
	 *
	 */
	goBackWithId() {
		const url = `/user-management/users`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Refresh user
	 *
	 * @param isNew: boolean
	 * @param id: number
	 */
	refreshUser(isNew: boolean = false, id = 0) {
		//console.log(`Entering refreshUser isNew: ${isNew} id: ${id}`);
		let url = this.router.url;
		if (!isNew) {
			this.router.navigate([url], { relativeTo: this.activatedRoute });
			return;
		}

		url = `/user-management/users/edit/${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Reset
	 */
	reset() {
		this.user = Object.assign({}, this.oldUser);
		this.createForm();
		this.hasFormErrors = false;
		this.userForm.markAsPristine();
  		this.userForm.markAsUntouched();
  		this.userForm.updateValueAndValidity();
	}

	findProfile(name:String):Profiles{
		return this.profilesResult.filter(profile=>profile.name.toLowerCase() == name.toLowerCase())[0];
	}

	/**
	 * Save data
	 *
	 * @param withBack: boolean
	 */
	onSumbit(withBack: boolean = false) {
		this.hasFormErrors = false;
		const controls = this.userForm.controls;
		/** check form */
		if (this.userForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			this.selectedTab = 0;
			return;
		}

		const editedUser = this.prepareUser();

		if (editedUser.id > 0) {
			this.updateUser(editedUser, withBack);
			return;
		}
		//console.table(editedUser);
		this.addUser(editedUser, withBack);
	}

	/**
	 * Returns prepared data for save
	 */
	prepareUser(): User {
		const controls = this.userForm.controls;
		const _user = new User();
		_user.clear();
		_user.accessToken = this.user.accessToken;
		_user.refreshToken = this.user.refreshToken;
		_user.id = this.user.id;
		_user.login = controls.username.value;	
		_user.mobile = controls.mobile.value;	
		_user.email = controls.email.value;
		_user.name = controls.name.value;
		_user.group_id = this.selectedProfile.id;
		_user.is_email_report = this.user.is_email_report;
		// _user.partner_id = this.currentUser.partner_id;
		if(controls.password.value!=""){
			// console.log("password: "+controls.password.value);
			_user.password_crypt = CryptoJS.MD5(controls.password.value).toString();;
			// console.log("password crypt: "+_user.password_crypt);
		}
		_user.write_uid = this.uid;
		return _user;
	}

	/**
	 * Add User
	 *
	 * @param _user: User
	 * @param withBack: boolean
	 */
	addUser(_user: User, withBack: boolean = false) {
		console.log("adduser");
		this.store.dispatch(new UserOnServerCreated({ user: _user }));
		const addSubscription = this.store.pipe(select(selectLastCreatedUserId)).subscribe(newId => {
			const message = `El nuevo usuario fue creado con éxito.`;
			this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, true);
			console.log(`CHECKING ...: newId:${newId} withBack: ${withBack}`);
			//Button Guardar will continue the creation
			if (newId) {
				if (withBack) {
					this.goBackWithId();
				} else {
					this.refreshUser(true, newId);
				}
			}
		});
		this.subscriptions.push(addSubscription);
	}

	/**
	 * Update user
	 *
	 * @param _user: User
	 * @param withBack: boolean
	 */
	updateUser(_user: User, withBack: boolean = false) {
		// Update User
		// tslint:disable-next-line:prefer-const

		const updatedUser: Update<User> = {
			id: _user.id,
			changes: _user
		};
		this.store.dispatch(new UserUpdated( { partialUser: updatedUser, user: _user }));
		const message = `El usuario ha sido actualizado con exito.`;
		this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, true);
		if (withBack) {
			this.goBackWithId();
		} else {
			this.refreshUser(false);
		}
	}

	/**
	 * Returns component title
	 */
	getComponentTitle() {
		let result = 'Crear usuario';
		if (!this.user || !this.user.id) {
			return result;
		}

		result = `Editar usuario - ${this.user.login}`;
		return result;
	}

	/**
	 * Close Alert
	 *
	 * @param $event: Event
	 */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}

	selectProfile(profile:Profiles){
		this.selectedProfile = profile;
		this.user.group_id = profile.id;
		this.user.group_name = profile.name;
	}
}
