// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import { AssemblerActions, AssemblerActionTypes } from '../_actions/assembler.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { Assembler } from '../_models/assembler.model';

// tslint:disable-next-line:no-empty-interface
export interface AssemblerState extends EntityState<Assembler> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedAssemblerId: number;
    lastUpdatedAssemblerId: number;
    lastDeletedAssemblerId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<Assembler> = createEntityAdapter<Assembler>();

export const initialAssemblerState: AssemblerState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery:  new QueryParamsModel({}),
    lastCreatedAssemblerId: undefined,
    lastUpdatedAssemblerId: undefined,
    lastDeletedAssemblerId: undefined,
    showInitWaitingMessage: true
});

export function assemblersReducer(state = initialAssemblerState, action: AssemblerActions): AssemblerState {
    switch  (action.type) {
        case AssemblerActionTypes.ASSEMBLER_ADDED: return adapter.addOne(action.payload.assembler, {
            ...state, lastCreatedAssemblerId: action.payload.assembler.id
        });
        case AssemblerActionTypes.ASSEMBLER_EDITED: return adapter.updateOne(action.payload.partialAssembler, 
            {...state, lastUpdatedAssemblerId: action.payload.partialAssembler.id});
        case AssemblerActionTypes.ASSEMBLER_PAGE_LOADED: 
            // console.log("reducer: page loaded.. "+JSON.stringify(action));
            return adapter.addMany(action.payload.assemblers, {
                ...initialAssemblerState,
                totalCount: action.payload.totalCount,
                lastQuery: action.payload.page,
                listLoading: false,
                showInitWaitingMessage: false
            });
        case AssemblerActionTypes.ASSEMBLER_ALL_LOADED: return adapter.addMany(action.payload.assemblers, {
            ...initialAssemblerState,
            listLoading: false,
            showInitWaitingMessage: false
        });
        case AssemblerActionTypes.ASSEMBLER_DELETED: return adapter.removeOne(action.payload.id, 
            {...state, lastDeletedAssemblerId: action.payload.id});
        // case UserActionTypes.UsersPageCancelled: return {
        //     ...state, listLoading: false, lastQuery: new QueryParamsModel({})
        // };
        default: return state;
    }
}

export const getAssemblerState = createFeatureSelector<AssemblerState>('assemblers');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
