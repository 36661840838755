// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { ShiftState } from '../_reducers/shift.reducers';
import { Shift, ShiftType } from '../_models/shift.model';

export const selectShiftsState = createFeatureSelector<ShiftState>('shifts');

export const selectShiftById = (shiftId: number) => createSelector(
    selectShiftsState,
    shiftsState => shiftsState.entities[shiftId]
);

export const selectShiftsPageLoading = createSelector(
    selectShiftsState,
    shiftsState => shiftsState.listLoading
);

// export const selectProductsActionLoading = createSelector(
//     selectShiftsState,
//     customersState => customersState.actionsloading
// );

export const selectShiftsPageLastQuery = createSelector(
    selectShiftsState,
    shiftsState => shiftsState.lastQuery
);

export const selectLastCreatedShiftId = createSelector(
    selectShiftsState,
    shiftsState => shiftsState.lastCreatedShiftId
);

export const selectLastUpdatedShiftId = createSelector(
    selectShiftsState,
    shiftsState => shiftsState.lastUpdatedShiftId
);

export const selectLastDeletedShiftId = createSelector(
    selectShiftsState,
    shiftsState => shiftsState.lastDeletedShiftId
);

export const selectShiftsInitWaitingMessage = createSelector(
    selectShiftsState,
    shiftsState => shiftsState.showInitWaitingMessage
);

export const selectShiftsInStore = createSelector(
    selectShiftsState,
    shiftsState => {
        const items: Shift[] = [];
        each(shiftsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: Shift[] = httpExtension.sortArray(items, shiftsState.lastQuery.sortField, shiftsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, shiftsState.totalCount, '');
    }
);

export const selectHasShiftsInStore = createSelector(
    selectShiftsInStore,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);

//SHIFT TYPES
export const selectShiftTypesState = state => state.shiftTypes;

export const shiftTypesLoaded = createSelector(
    selectShiftTypesState,
    shiftTypesState => shiftTypesState.shiftTypesLoaded
)

export const selectShiftTypes = createSelector(
    selectShiftTypesState,
    shiftTypesState => {
        const items: ShiftType[] = [];
        each(shiftTypesState.shiftTypes, element => {
            items.push(element);
        });
        return items;
    }
)