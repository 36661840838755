import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, interval, of, Subscription } from 'rxjs';
import { AppState } from '../../../../../core/reducers';
import { AssemblersDataSource, Assembler, AssemblerGetAll, AssemblerService, ShiftService, selectShiftById, Shift, ShiftGetPage, ShiftsDataSource } from '../../../../../core/plant-configuration';
import { ProductionService } from '../../../../../core/production';
import { filter, first, map, startWith, switchMap, tap } from 'rxjs/operators';
import { QueryParamsModel } from '../../../../../core/_base/crud';
import {formatDate } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'kt-mac-day-dashboard',
  templateUrl: './mac-day-dashboard.component.html',
  styleUrls: ['./mac-day-dashboard.component.scss']
})

export class MacDayDashboardComponent implements OnInit, OnDestroy {
  dataSource: AssemblersDataSource;
  shiftDataSource: ShiftsDataSource;

  assemblersResult: any[] = [];
  private subscriptions: Subscription[] = [];
  private instantVelSubs: Subscription[] = [];
  private shiftSubs: Subscription[] = [];
  acumProduction = 0;
  title: string = "Producción: Sin Turno"
  currentShift: Shift = null;
  date: string = "Sin fecha";

  constructor(private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private productionService: ProductionService,
    private shiftService: ShiftService){}
  
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe());
    this.instantVelSubs.forEach(sub=>sub.unsubscribe());
    this.shiftSubs.forEach(sub=>sub.unsubscribe());
  }

  ngOnInit(){
    document.getElementById("btnfechahora").innerHTML = formatDate(new Date(),'dd/MM/yyyy','en') +' | ' + formatDate(new Date(),'hh:mm','en');    
    this.dataSource = new AssemblersDataSource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject.pipe(
      filter(res=>res!=null && res!=undefined && res.length>0),
      first()
    ).subscribe(res => {
      res = res.sort((a,b)=> a.pos-b.pos);
      this.assemblersResult = res.map(assembler=>
        Object.assign({instantVelocity:0, instantVelDelimiters:[1], shiftVelDelimiters:[1], shiftVelocity:0, shiftUnits:0},assembler));
      this.syncInstantVelocity();
      // console.log(this.assemblersResult);
      this.cdr.detectChanges();
    });
    const shiftsSubscription = this.dataSource.entitySubject.pipe(
      filter(res=>res!=null && res!=undefined && res.length>0),
      first()
    ).subscribe((shifts)=>{
      this.syncShift();
    });
    this.subscriptions.push(entitiesSubscription);
    this.subscriptions.push(shiftsSubscription);
    this.loadAssemblersList();
    this.loadShiftsList();
  }

  loadAssemblersList() {
    this.store.dispatch(new AssemblerGetAll({}));
  }

  loadShiftsList() {
    const queryParams = new QueryParamsModel(
      {active: true},
      "ASC",
      "shifts.id",
      0,
      10
    );
    // Call request from server
    // console.log("loadShiftList");
    this.store.dispatch(new ShiftGetPage({ page: queryParams }));
  }

  navigateToRealtime(assembler:Assembler){
    this.router.navigate([`production/realtime/${assembler.id}`]);
  }

  syncInstantVelocity(){
    this.instantVelSubs.forEach(sub=>sub.unsubscribe());
    this.instantVelSubs=[];
    // this.assemblersResult.forEach(assembler=>{
    //   assembler.instantVelocity = 0;

    //   this.instantVelSubs.push(
    //     interval(1000*60).pipe(
    //       startWith(0),
    //       switchMap(()=>{
    //         return this.productionService.getInstantVelocity(assembler);
    //       })
    //     ).subscribe(vel=>{
    //       if(vel>assembler.instantVelDelimiters[0]){
    //         assembler.instantVelDelimiters[0] = vel;
    //         console.log("nueva vel instantanea maxima: "+vel);
    //       }
    //       assembler.instantVelocity = vel;
    //       this.cdr.detectChanges();
    //     })
    //   );
    // });
    // this.assemblersResult.forEach(assembler=>{
    //   assembler.instantVelocity = 0;

    this.instantVelSubs.push(
      interval(1000*60).pipe(
        startWith(0),
        switchMap(()=>{
          return this.productionService.getAllInstantVelocity();
        })
      ).subscribe(velocityList=>{
        console.log("instant velocity list");
        console.dir(velocityList);
        velocityList.forEach(vel=>{
          const assembler = this.assemblersResult.find(assembler=>assembler.id==vel.assembler_id);
          assembler.instantVelocity = 0;
          const velocity = parseFloat(vel.velocity);
          if(velocity>assembler.instantVelDelimiters[0]){
            assembler.instantVelDelimiters[0] = velocity;
            console.log("nueva vel instantanea maxima: "+velocity);
          }
          assembler.instantVelocity = velocity;
          this.cdr.detectChanges();
        });        
      })
      );
    // });
  }

  syncShift(){
    this.shiftSubs.forEach(sub=>sub.unsubscribe());
    this.shiftSubs=[];
    this.shiftSubs.push(
      interval(1000*80).pipe(
        startWith(0),
        switchMap(()=>{
          return this.shiftService.currentId();
        }),
        tap(response=>{
          const datestr = response.date;
          const options = { weekday: 'long', year: 'numeric', month: 'long', 
            day: 'numeric', hour:'numeric', minute:'numeric' };
          this.date = new Date(datestr).toLocaleDateString("es-ES", options);
        }),
        switchMap((response)=>{
          const shiftId = response.shiftId;
          return this.store.pipe(
            select(selectShiftById(shiftId))
          );
        }),
        switchMap((shift)=>{
          if(shift){
            this.currentShift = shift;
            this.title = `Producción: ${shift.name}`;
          }
          return this.productionService.getShiftStatsPerAssembler(shift)
        })
      ).subscribe((statsList)=>{
        console.dir(statsList);
        this.acumProduction = 0;
        statsList.forEach(stats=>{
          const assembler = this.assemblersResult.find(assembler=>assembler.id == stats.assembler_id);
          const vel =  Math.round(parseFloat(stats.velocity_avg)*100)/100;
          assembler.shiftVelocity = vel;
          if(assembler.shiftVelDelimiters[0]<vel){
            assembler.shiftVelDelimiters[0] = vel;
          }
          assembler.shiftUnits = stats.total_units;
          this.acumProduction += parseFloat(stats.total_units);
          console.dir(assembler);
        });

      })
    )
  }

  // assemblerInstantVelocity(assembler: Assembler){
  //   return interval(1000).pipe(
  //     switchMap(()=>{
  //       return this.productionService.getInstantVelocity(assembler)
  //     })
  //   );
  // }
}