import { Component, OnInit,ChangeDetectionStrategy,ChangeDetectorRef } from '@angular/core';
import { combineLatest, interval, Subscription } from 'rxjs';
import {formatDate } from '@angular/common';
import { Assembler, AssemblerGetAll, AssemblersDataSource, selectShiftById, Shift, ShiftGetPage, ShiftsDataSource, ShiftService } from '../../../../../core/plant-configuration';
import { ProductionService } from '../../../../../core/production';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { filter, first, startWith, switchMap, tap } from 'rxjs/operators';
import { QueryParamsModel } from '../../../../../core/_base/crud';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'kt-realtime-dashboard',
  templateUrl: './realtime-dashboard.component.html',
  styleUrls: ['./realtime-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RealtimeDashboardComponent implements OnInit {
  
  private subscriptions: Subscription[] = [];
  currentShift: Shift;
  currentAssembler: any;
  dataSource: AssemblersDataSource;
  shiftDataSource: ShiftsDataSource;
  date: string = "Sin fecha";
  stoppedTime: string = "00:00";
  productionTime: string = "00:00";
  effectiveTime: string = "0";

  assemblersResult: any[] = [];
  private instantVelSubs: Subscription[] = [];
  private shiftSubs: Subscription[] = [];
  private historySubs: Subscription[] = [];
  private history: any;
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private productionService: ProductionService,
    private store: Store<AppState>,
    private shiftService: ShiftService
    ) {}

  get_tittle(){
    if(this.currentShift && this.currentAssembler)
      return `Producción: ${this.currentShift.name} \n Ensambladora ${this.currentAssembler.iiot_code}`
    return `Sin turno. Sin máquina.`
  }
  ngOnInit() {
    document.getElementById("btnfechahora").innerHTML = formatDate(new Date(),'dd/MM/yyyy','en') +' | ' + formatDate(new Date(),'hh:mm','en');    
    this.dataSource = new AssemblersDataSource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject.pipe(
      filter(res=>res!=null && res!=undefined && res.length>0),
      first()
    ).subscribe(res => {
      res = res.sort((a,b)=> a.pos>b.pos?b:a);
      this.assemblersResult = res.map(assembler=>
        Object.assign({instantVelocity:0, instantVelDelimiters:[1], shiftVelDelimiters:[1], shiftVelocity:0, shiftUnits:0},assembler));
      
      if(this.assemblersResult.length>0){
        this.subscriptions.push(
          this.activatedRoute.params.subscribe(params=>{
            const id = params.id;
            if(id){
              this.selectAssembler(this.assemblersResult.find(assembler=>assembler.id==id));
            }else{
              this.selectAssembler(this.assemblersResult[0]);
            }
          })
        )
      }
      this.cd.detectChanges();
    });
    
    this.subscriptions.push(entitiesSubscription);
    this.loadAssemblersList();
  }

  loadAssemblersList() {
    this.store.dispatch(new AssemblerGetAll({}));
  }

  loadShiftsList() {
    const queryParams = new QueryParamsModel(
      {active: true},
      "ASC",
      "shifts.id",
      0,
      10
    );
    // Call request from server
    // console.log("loadShiftList");
    this.store.dispatch(new ShiftGetPage({ page: queryParams }));
  }

  syncInstantVelocity(){
    this.instantVelSubs.forEach(sub=>sub.unsubscribe());
    this.instantVelSubs=[];
    if(this.currentAssembler){
      this.currentAssembler.instantVelocity = 0;

      this.instantVelSubs.push(
        interval(1000*60).pipe(
          startWith(0),
          switchMap(()=>{
            return this.productionService.getInstantVelocity(this.currentAssembler);
          })
        ).subscribe(vel=>{
          if(vel>this.currentAssembler.instantVelDelimiters[0]){
            this.currentAssembler.instantVelDelimiters[0] = vel;
            console.log("nueva vel instantanea maxima: "+vel);
          }
          this.currentAssembler.instantVelocity = vel;
          this.cd.detectChanges();
        })
      );
    }
  }
  
  syncShift(){
    this.shiftSubs.forEach(sub=>sub.unsubscribe());
    this.shiftSubs=[];
    this.shiftSubs.push(
      interval(1000*80).pipe(
        startWith(0),
        switchMap(()=>{
          return this.shiftService.currentId();
        }),
        tap(response=>{
          const datestr = response.date;
          const options = { weekday: 'long', year: 'numeric', month: 'long', 
            day: 'numeric', hour:'numeric', minute:'numeric' };
          this.date = new Date(datestr).toLocaleDateString("es-ES", options);
        }),
        switchMap((response)=>{
          const shiftId = response.shiftId;
          console.log("shift id: "+shiftId);
          return this.store.pipe(
            select(selectShiftById(shiftId))
          );
        })
      ).subscribe((shift)=>{
        if(this.currentShift && shift.id!=this.currentShift.id){
          // console.log("El turno ha cambiado!");
        }
        if(shift){
          this.currentShift = shift;
          if(this.currentAssembler){
            this.syncHistory();
            this.shiftSubs.push(
              this.productionService.getShiftVelocity(this.currentAssembler, shift).subscribe(vel=>{
                try{
                  vel =  Math.round(vel*100)/100;
                }catch(err){

                }
                this.currentAssembler.shiftVelocity = vel;
                if(this.currentAssembler.shiftVelDelimiters[0]<vel){
                  this.currentAssembler.shiftVelDelimiters[0] = vel;
                }
                this.cd.detectChanges();
              })
            );
            this.shiftSubs.push(
              this.productionService.getShiftTotalUnits(this.currentAssembler, shift).subscribe(units=>{
                  this.currentAssembler.shiftUnits = units;
                  this.cd.detectChanges();
                }
              )
            );
          }
          // const totalUnits$ = this.assemblersResult.map(assembler => this.productionService.getShiftTotalUnits(assembler, shift));
          //   this.shiftSubs.push(
          //     combineLatest(totalUnits$).subscribe(units=>{
          //       this.acumProduction = units.reduce((prev, curr, idx)=>{
          //         return prev+curr;
          //       });
          //       for(let i in units){
          //         this.assemblersResult[i].shiftUnits = units[i];
          //       }
          //     })
          //   );
        }
      })
    )
  }

  syncHistory(){
    if(this.currentAssembler && this.currentShift){
      this.historySubs.forEach(sub=>sub.unsubscribe());
      this.historySubs = [];
      this.historySubs.push(
        interval(1000*120).pipe(
          startWith(0),
          switchMap(()=>{
            return this.productionService.getShiftHistoryPerMinute(this.currentAssembler, this.currentShift)
          })
        ).subscribe(history => {
          if(!history || history.length==0)
            return
          this.history = [{
            name: "Máquina "+this.currentAssembler.iiot_code,
            series: history.map(row=>{return {
              name: new Date(row.ts), 
              value:row.units,
              tooltipText: [`Máquina ${this.currentAssembler.iiot_code}:`,`Valor: ${row.units}`,`Hora: ${this.dateToTimeStr(new Date(row.ts))}`],
            }})
          }];
          
          // console.log("history: "+JSON.stringify(this.history));
          // console.log("Minutos: "+history.length);
          const shift_duration_minutes = this.currentShift.shift_duration_minutes;
          const collation = this.currentShift.collation_time_minutes;
          const start_minutes = this.currentShift.start_time_hour*60 + this.currentShift.start_time_minute;
          const end_minutes = this.currentShift.end_time_hour*60 + this.currentShift.end_time_minute;
          const total_minutes = end_minutes-start_minutes;
          const date = new Date();
          var minutes = date.getMinutes();
          var hour = date.getHours();
          const elapsed_minutes = minutes + hour*60 - start_minutes;
          var worked_minutes = 0;
          for(let sample of history){
            worked_minutes += sample.units>0?1:0;
          }
          const partial_elapsed_minutes = elapsed_minutes-collation*(elapsed_minutes/total_minutes);
          const effective = worked_minutes/partial_elapsed_minutes;
          console.log(`Elapsed minutes: ${elapsed_minutes}. Worked minutes: ${worked_minutes}. Partial elapsed minutes: ${partial_elapsed_minutes}. Effective: ${effective}`);
          this.effectiveTime = `${Math.floor(effective*100)}`;
          const stopped = (1-effective)*partial_elapsed_minutes;
          this.stoppedTime = this.minutesToTimeStr(stopped);
          const production = partial_elapsed_minutes-stopped;
          this.productionTime = this.minutesToTimeStr(production);
          
          this.cd.detectChanges();
      }));
    }
  }

  selectAssembler(assembler: Assembler){
    this.currentAssembler = assembler;
    this.stoppedTime = "00:00";
    this.productionTime = "00:00";
    this.effectiveTime = "0";
    this.syncInstantVelocity();
    this.syncShift();
    // this.syncHistory();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe());
    this.instantVelSubs.forEach(sub=>sub.unsubscribe());
    this.shiftSubs.forEach(sub=>sub.unsubscribe());
    this.historySubs.forEach(sub=>sub.unsubscribe());
  }

  minutesToTimeStr(minutes){
    // let date = new Date();
    const hour = Math.floor(minutes/60);
    const minute = Math.round(minutes%60);
    var time = "";
    if(hour<10){
      time +="0";
    }
    time += `${hour}:`;
    if(minute<10){
      time+="0";
    }
    time += `${minute}`;
    return time;
  }

  dateToTimeStr(date:Date){
    var minutes = date.getMinutes();
    var hour = date.getHours();
    return this.minutesToTimeStr(hour*60 + minutes);
  }
}