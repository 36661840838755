export var data1Mac1 = [
  {
    name: "Maquina 1",
    series: [
      {
        name: "00:00:00",
        value: 20
      },
      {
        name: "02:00:00",
        value: 30
      },
      {
        name: "04:00:00",
        value: 64
      },
      {
        name: "06:00:00",
        value: 54
      },
      {
        name: "08:00:00",
        value: 24
      },
      {
        name: "10:00:00",
        value: 64
      },
      {
        name: "12:00:00",
        value: 94
      },
      {
        name: "14:00:00",
        value: 44
      },
      {
        name: "16:00:00",
        value: 10
      },
      {
        name: "18:00:00",
        value: 34
      },
      {
        name: "20:00:00",
        value: 24
      },
      {
        name: "22:00:00",
        value: 14
      }
    ]
  }    
];

  export var data2Mac1 = [
    {
      name: "Maquina 1",
      series: [
        {
          name: "00:00:00",
          value: 620
        },
        {
          name: "02:00:00",
          value: 730
        },
        {
          name: "04:00:00",
          value: 894
        },
        {
          name: "06:00:00",
          value: 854
        },
        {
          name: "08:00:00",
          value: 624
        },
        {
          name: "10:00:00",
          value: 764
        },
        {
          name: "12:00:00",
          value: 694
        },
        {
          name: "14:00:00",
          value: 814
        },
        {
          name: "16:00:00",
          value: 714
        },
        {
          name: "18:00:00",
          value: 514
        },
        {
          name: "20:00:00",
          value: 714
        },
        {
          name: "22:00:00",
          value: 414
        }
      ]
    }    
  ];
  
  export var data3Mac1 = [
    {
        name: "Velocidad",
        value: 20
      }
  ];

  export var data5Mac1 = [
    {
      name: "Maquina 1",
      series: [
        {
          name: "00:00:00",
          value: 620
        },
        {
          name: "02:00:00",
          value: 730
        },
        {
          name: "04:00:00",
          value: 894
        },
        {
          name: "06:00:00",
          value: 854
        },
        {
          name: "08:00:00",
          value: 624
        },
        {
          name: "10:00:00",
          value: 764
        },
        {
          name: "12:00:00",
          value: 694
        },
        {
          name: "14:00:00",
          value: 814
        },
        {
          name: "16:00:00",
          value: 714
        },
        {
          name: "18:00:00",
          value: 514
        },
        {
          name: "20:00:00",
          value: 714
        },
        {
          name: "22:00:00",
          value: 414
        }
      ]
    }    
  ];