// Angular
import { Component, OnInit, Input } from '@angular/core';
// RxJS
import { BehaviorSubject, Observable } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
// Lodash
import { each, find, remove } from 'lodash';
// State
import { AppState } from '../../../../../../core/reducers';
// Auth
import { Role, selectAllRoles } from '../../../../../../core/auth';
import { Profiles, selectAllProfiles } from '../../../../../../core/auth';
@Component({
	selector: 'kt-user-roles-list',
	templateUrl: './user-roles-list.component.html'
})
export class UserRolesListComponent implements OnInit {
	// Public properties
	// Incoming data
	@Input() loadingSubject = new BehaviorSubject<boolean>(false);
	@Input() profileSubject: BehaviorSubject<number[]>;
	allUserProfiles$: Observable<Profiles[]>;
	allProfiles: Profiles[] = [];
	unassignedProfiles: Profiles[] = [];
	assignedProfiles: Profiles[] = [];
	profileIdForAdding: number;


	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(private store: Store<AppState>) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.allUserProfiles$ = this.store.pipe(select(selectAllProfiles));
		this.allUserProfiles$.subscribe((res: Profiles[]) => {
			each(res, (_profile: Profiles) => {
				this.allProfiles.push(_profile);
				this.unassignedProfiles.push(_profile);
			});
			each(this.profileSubject.value, (profileId: number) => {
				const profile = find(this.allProfiles, (_profile: Profiles) => {
					return _profile.id === profileId;
				});

				if (profile) {
					this.assignedProfiles.push(profile);
					remove(this.unassignedProfiles, (el) => el.id === profile.id);
				}
			});
		});

	}

	/**
	 * Assign role
	 */
	assignProfile() {

		if (this.profileIdForAdding === 0) {
			return;
		}

		const profile = find(this.allProfiles, (_profile: Profiles) => {
			return _profile.id === (+this.profileIdForAdding);
		});

		if (profile) {
			this.assignedProfiles.push(profile);
			remove(this.unassignedProfiles, (el) => el.id === profile.id);
			this.profileIdForAdding = 0;
			this.updateProfiles();
		}
	}

	/**
	 * Unassign role
	 *
	 * @param role: Role
	 */
	unassignProfile(profile: Profiles) {
		this.profileIdForAdding = 0;
		this.unassignedProfiles.push(profile);
		remove(this.assignedProfiles, el => el.id === profile.id);
		this.updateProfiles();
	}

	/**
	 * Update roles
	 */
	updateProfiles() {
		const _profiles = [];
		each(this.assignedProfiles, elem => _profiles.push(elem.id));
		this.profileSubject.next(_profiles);
	}
}
