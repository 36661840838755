import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable} from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import {
    ShiftActionTypes,
    ShiftAdd,
    ShiftAdded,
    ShiftAllLoaded,
    ShiftDelete,
    ShiftDeleted,
    ShiftEdit,
    ShiftEdited,
    ShiftGetAll,
    ShiftGetPage,
    ShiftGetTypes,
    ShiftPageLoaded,
    ShiftTypesLoaded
} from '../_actions/shift.actions';
import { ShiftService } from '../_services/shift.service';


@Injectable()
export class ShiftEffects {

    constructor(
        private actions: Actions,
        private shiftService: ShiftService,
        private router: Router,
    ) {}

    @Effect()
    add: Observable<any> = this.actions.pipe(
        ofType(ShiftActionTypes.SHIFT_ADD),
        map((action: ShiftAdd) => action.payload),
        switchMap(payload => {
            return this.shiftService.add(payload.shift).pipe(
                map((shiftId)=>{ //Adecuarse a la response recibida de api real
                    //console.log("add shift");
                    //console.log(JSON.stringify(payload.shift));
                    let shift = {
                        ...payload.shift,
                        id: shiftId
                    };
                    return new ShiftAdded({shift: shift});
                })
            );
        })  
    );

    @Effect()
    edit: Observable<any> = this.actions.pipe(
        ofType(ShiftActionTypes.SHIFT_EDIT),
        map((action: ShiftEdit) => action.payload),
        switchMap(payload => {
            return this.shiftService.edit(payload.shift).pipe(
                map((response)=>{ //Adecuarse a la response recibida de api real
                    //console.log("edit shift");
                    //console.log(JSON.stringify(payload.shift));
                    return new ShiftEdited({partialShift: payload.shift});
                })
            );
        })  
    );

    @Effect()
    delete: Observable<any> = this.actions.pipe(
        ofType(ShiftActionTypes.SHIFT_DELETE),
        map((action: ShiftDelete) => action.payload),
        switchMap(payload => {
            return this.shiftService.delete(payload.shift).pipe(
                map((response)=>{ //Adecuarse a la response recibida de api real
                    //console.log("delete shift");
                    //console.log(JSON.stringify(payload.shift));
                    return new ShiftDeleted({id: payload.shift.id});
                })
            );
        })  
    );

    @Effect()
    getPage: Observable<any> = this.actions.pipe(
        ofType(ShiftActionTypes.SHIFT_GET_PAGE),
        map((action: ShiftGetPage) => action.payload),
        switchMap(payload => {
            return this.shiftService.getPage(payload.page).pipe(
                map((response)=>{ //Adecuarse a la response recibida de api real
                    // console.log("get page shift");
                    // console.log(JSON.stringify(response));
                    return {shifts: response.items, page: payload.page, totalCount: response.totalCount};
                })
            );
        }),
        map((response)=>{
            return new ShiftPageLoaded(response);
        })
    );

    @Effect()
    getTypes: Observable<any> = this.actions.pipe(
        ofType<ShiftGetTypes>(ShiftActionTypes.SHIFT_GET_TYPES),
        map((action: ShiftGetTypes) => action.payload),
        switchMap(payload => {
            return this.shiftService.getTypes().pipe(
                map(shiftTypes=>{
                    //console.log("get types shift");
                    //console.log(JSON.stringify(payload));
                    return {shiftTypes: shiftTypes};
                })
            );
        }),
        map((newPayload)=>{ //Adecuarse a la response recibida de api real            
            //console.log(JSON.stringify(newPayload));
            return new ShiftTypesLoaded(newPayload);
        })
    );

    // @Effect()
    // getAll: Observable<any> = this.actions.pipe(
    //     ofType(ShiftActionTypes.SHIFT_GET_ALL),
    //     map((action: ShiftGetAll) => action.payload),
    //     switchMap(payload => {            
    //         return this.shiftService.getAll().pipe(
    //             map((shifts)=>{ //Adecuarse a la response recibida de api real
    //                 //console.log("get all shifts");
    //                 //console.log(JSON.stringify(shifts));
    //                 return shifts
    //             })
    //         );
    //     }),
    //     map((shifts)=>{
    //         return new ShiftAllLoaded({shifts: shifts});
    //     })
    // );

    @Effect({dispatch:false})
    pageLoaded: Observable<any> = this.actions.pipe(
        ofType(ShiftActionTypes.SHIFT_PAGE_LOADED),
        //tap((action:ShiftPageLoaded)=>console.log("shift page loaded"))
    );

    // @Effect({dispatch:false})
    // allLoaded: Observable<any> = this.actions.pipe(
    //     ofType(ShiftActionTypes.SHIFT_ALL_LOADED),
    //     //tap((action:ShiftAllLoaded)=>console.log("shift all loaded"))
    // );

    @Effect({dispatch:false})
    added: Observable<any> = this.actions.pipe(
        ofType(ShiftActionTypes.SHIFT_ADDED),
        //tap((action:ShiftAdded)=>console.log("shift added"))
    );

    @Effect({dispatch:false})
    edited: Observable<any> = this.actions.pipe(
        ofType(ShiftActionTypes.SHIFT_EDITED),
        //tap((action:ShiftEdited)=>console.log("shift edited"))
    );

    @Effect({dispatch:false})
    deleted: Observable<any> = this.actions.pipe(
        ofType(ShiftActionTypes.SHIFT_DELETED),
        //tap((action:ShiftDeleted)=>console.log("shift deleted"))
    );
}