import { BaseModel } from '../../_base/crud';

export class Shift extends BaseModel {
    id: number;
    create_uid: number;
    write_uid: number;
    write_date: any;
    create_date: any;
    name:string;
    start_time_hour:number;
    start_time_minute:number;
    start_time_day:string;
    end_time_hour:number;
    end_time_minute:number;
    end_time_day:string;
    shift_duration_minutes:number;
    collation_time_minutes:number;
    shift_type_id: number;
    shift_type_name: string;
    company_id: number;
    active: boolean;
    clear(){
        this.id                         = undefined;
        this.create_uid                 = undefined;
        this.write_date                 = undefined;
        this.write_uid                  = undefined;
        this.create_date                = undefined;
        this.company_id                 = undefined;
        this.name                       = "";
        this.collation_time_minutes     = undefined;
        this.shift_duration_minutes     = undefined;
        this.end_time_day               = undefined;
        this.end_time_minute            = undefined;
        this.end_time_hour              = undefined;
        this.start_time_day             = undefined;
        this.start_time_minute          = undefined;
        this.start_time_hour            = undefined;
        this.shift_type_id              = undefined;
        this.shift_type_name            = "";
        this.active                     = undefined;
    }
}

export class ShiftType extends BaseModel {
    id: number;
    create_uid: number;
    write_uid: number;
    write_date: any;
    create_date: any;
    company_id: number;
    name: string;
    clear(){
        this.id                         = undefined;
        this.create_uid                 = undefined;
        this.write_date                 = undefined;
        this.write_uid                  = undefined;
        this.create_date                = undefined;
        this.company_id                 = undefined;
        this.name                       = "";
    }
}