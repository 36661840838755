import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



import { MatButtonModule, MatIconModule, MatPaginatorModule, MatProgressSpinnerModule, MatSortModule, MatTableModule, } from '@angular/material';
import { CoreModule } from '../../../../core/core.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// Datatable
import { DataTableComponent } from './general/data-table/data-table.component';
// General widgets
import { Widget1Component } from './widget1/widget1.component';
import { Widget4Component } from './widget4/widget4.component';
import { Widget5Component } from './widget5/widget5.component';
import { Widget12Component } from './widget12/widget12.component';
import { Widget14Component } from './widget14/widget14.component';
import { Widget26Component } from './widget26/widget26.component';
import { Timeline2Component } from './timeline2/timeline2.component';
import { Pavilion1HistorycComponent } from './pavilion1-historyc/pavilion1-historyc.component';

import {PavilionHistoryService} from '../../../../core/production/index';
import { PerfomanceHistorycPavilionComponent } from './perfomance-historyc-pavilion/perfomance-historyc-pavilion.component';
import { PerformanceAccHistorycPavilionComponent } from './performance-acc-historyc-pavilion/performance-acc-historyc-pavilion.component';
import { NourishmentHistorycPavilionComponent } from './nourishment-historyc-pavilion/nourishment-historyc-pavilion.component';
import { DeadlyHistorycPavilionComponent } from './deadly-historyc-pavilion/deadly-historyc-pavilion.component';
import { DeadlyAccHistorycPavilionComponent } from './deadly-acc-historyc-pavilion/deadly-acc-historyc-pavilion.component';
import { WaterHistorycPavilionComponent } from './water-historyc-pavilion/water-historyc-pavilion.component';
import { TemperatureHistorycPavilionComponent } from './temperature-historyc-pavilion/temperature-historyc-pavilion.component'

@NgModule({
	declarations: [
		DataTableComponent,
		// Widgets
		Widget1Component,
		Widget4Component,
		Widget5Component,
		Widget12Component,
		Widget14Component,
		Widget26Component,
		Timeline2Component,
		Pavilion1HistorycComponent,
		PerfomanceHistorycPavilionComponent,
		PerformanceAccHistorycPavilionComponent,
		NourishmentHistorycPavilionComponent,
		DeadlyHistorycPavilionComponent,
		DeadlyAccHistorycPavilionComponent,
		WaterHistorycPavilionComponent,
		TemperatureHistorycPavilionComponent,
	],
	exports: [
		DataTableComponent,
		// Widgets
		Widget1Component,
		Widget4Component,
		Widget5Component,
		Widget12Component,
		Widget14Component,
		Widget26Component,
		Timeline2Component,
		Pavilion1HistorycComponent,
		DeadlyAccHistorycPavilionComponent,
		DeadlyHistorycPavilionComponent,
		PerformanceAccHistorycPavilionComponent,
		NourishmentHistorycPavilionComponent,
		WaterHistorycPavilionComponent,
		TemperatureHistorycPavilionComponent
	],
	imports: [
		CommonModule,
		PerfectScrollbarModule,
		MatTableModule,
		CoreModule,
		MatIconModule,
		MatButtonModule,
		MatProgressSpinnerModule,
		MatPaginatorModule,
		MatSortModule,
		
		//PavilionHistoryService
	],
	providers: [
		PavilionHistoryService
	]
})
export class WidgetModule {
}
