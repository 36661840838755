// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { AssemblerState } from '../_reducers/assembler.reducers';
import { Assembler } from '../_models/assembler.model';

export const selectAssemblersState = createFeatureSelector<AssemblerState>('assemblers');

export const selectAssemblerById = (assemblerId: number) => createSelector(
    selectAssemblersState,
    assemblersState => assemblersState.entities[assemblerId]
);

export const selectAssemblersPageLoading = createSelector(
    selectAssemblersState,
    assemblersState => assemblersState.listLoading
);

// export const selectProductsActionLoading = createSelector(
//     selectAssemblersState,
//     customersState => customersState.actionsloading
// );

export const selectAssemblersPageLastQuery = createSelector(
    selectAssemblersState,
    assemblersState => assemblersState.lastQuery
);

export const selectLastCreatedAssemblerId = createSelector(
    selectAssemblersState,
    assemblersState => assemblersState.lastCreatedAssemblerId
);

export const selectLastUpdatedAssemblerId = createSelector(
    selectAssemblersState,
    assemblersState => assemblersState.lastUpdatedAssemblerId
);

export const selectLastDeletedAssemblerId = createSelector(
    selectAssemblersState,
    assemblersState => assemblersState.lastDeletedAssemblerId
);

export const selectAssemblersInitWaitingMessage = createSelector(
    selectAssemblersState,
    assemblersState => assemblersState.showInitWaitingMessage
);

export const selectAssemblersInStore = createSelector(
    selectAssemblersState,
    assemblersState => {
        const items: Assembler[] = [];
        each(assemblersState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: Assembler[] = httpExtension.sortArray(items, assemblersState.lastQuery.sortField, assemblersState.lastQuery.sortOrder);
        return new QueryResultsModel(result, assemblersState.totalCount, '');
    }
);

export const selectHasAssemblersInStore = createSelector(
    selectAssemblersInStore,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);
