import { Injectable } from '@angular/core';
import { IMqttMessage, MqttService } from "ngx-mqtt";
import { Observable } from "rxjs";

@Injectable()
export class EventMqttService {

//var topic1="/IR4HUB/ASML/PLANTA_LIRAY/TEMP";
//var topic2="/IR4HUB/ASML/PLANTA_LIRAY/ACUM";
//var topic3="/IR4HUB/ASML/PLANTA_LIRAY/VEL";

  private endpoint: string;

  constructor(
    private _mqttService: MqttService,
  ) {
    this.endpoint = '/IR4HUB/ASML/PLANTA_LIRAY';
  }

  topic(deviceId: string): Observable<IMqttMessage> {

    let topicName = `${this.endpoint}/${deviceId}`;     
    console.log('Entering EventMqttService::topic',topicName)
    return this._mqttService.observe(topicName);
  }
}