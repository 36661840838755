import { Action } from '@ngrx/store';

export enum HylineActionTypes {
    HYLINE_EDIT = '[HYLINE] HylineEdit',
    HYLINE_EDITED = '[HYLINE] HylineEdited',
    HYLINE_ADD = '[HYLINE] HylineAdd',
    HYLINE_ADDED = '[HYLINE] HylineAdded',
    HYLINE_DELETE = '[HYLINE] HylineDelete',
    HYLINE_DELETED = '[HYLINE] HylineDeleted',
    HYLINE_GET_PAGE = '[HYLINE] HylineGetPage',
    HYLINE_PAGE_LOADED = '[HYLINE] HylinePageLoaded',
    HYLINE_GET_ALL = '[HYLINE] HylineGetAll',
    HYLINE_ALL_LOADED = '[HYLINE] HylineAllloaded',
    HYLINE_LOAD_DATA_PAGE = '[HYLINE] HylineLoadDataPage',
    HYLINE_DATA_PAGE_LOADED = '[HYLINE] HylineDataPageLoaded',
    HYLINE_LOAD_BRE_DATA_PAGE = '[HYLINE] HylineLoadBreDataPage',
    HYLINE_BRE_DATA_PAGE_LOADED = '[HYLINE] HylineBreDataPageLoaded',
    HYLINE_LOAD_HAV_DATA_PAGE = '[HYLINE] HylineLoadHavDataPage',
    HYLINE_HAV_DATA_PAGE_LOADED = '[HYLINE] HylineHavDataPageLoaded',
    HYLINE_GET_TYPES = "[HYLINE] HylineGetTypes",
    HYLINE_TYPES_LOADED = "[HYLINE] HylineTypesLoaded",
    HYLINE_GET_DATA_TYPES = "[HYLINE] HylineGetDataTypes",
    HYLINE_DATA_TYPES_LOADED = "[HYLINE] HylineDataTypesLoaded"
}

export class HylineAdd implements Action {
    readonly type = HylineActionTypes.HYLINE_ADD;
    constructor(public payload: any) {}
}

export class HylineEdit implements Action {
    readonly type = HylineActionTypes.HYLINE_EDIT;
    constructor(public payload: any) {}
}

export class HylineGetPage implements Action {
    readonly type = HylineActionTypes.HYLINE_GET_PAGE;
    constructor(public payload: any) {}
}

export class HylinePageLoaded implements Action {
    readonly type = HylineActionTypes.HYLINE_PAGE_LOADED;
    constructor(public payload: any) {}
}

export class HylineAdded implements Action {
    readonly type = HylineActionTypes.HYLINE_ADDED;
    constructor(public payload: any) {}
}

export class HylineEdited implements Action {
    readonly type = HylineActionTypes.HYLINE_EDITED;
    constructor(public payload: any) {}
}

export class HylineDelete implements Action {
    readonly type = HylineActionTypes.HYLINE_DELETE;
    constructor(public payload: any) {}
}

export class HylineDeleted implements Action {
    readonly type = HylineActionTypes.HYLINE_DELETED;
    constructor(public payload: any) {}
}

export class HylineGetAll implements Action {
    readonly type = HylineActionTypes.HYLINE_GET_ALL;
    constructor(public payload: any) {}
}

export class HylineAllLoaded implements Action {
    readonly type = HylineActionTypes.HYLINE_ALL_LOADED;
    constructor(public payload: any) {}
}

export class HylineDataPageLoaded implements Action {
    readonly type = HylineActionTypes.HYLINE_DATA_PAGE_LOADED;
    constructor(public payload: any) {}
}

export class HylineLoadDataPage implements Action {
    readonly type = HylineActionTypes.HYLINE_LOAD_DATA_PAGE;
    constructor(public payload: any) {}
}

export class HylineBreDataPageLoaded implements Action {
    readonly type = HylineActionTypes.HYLINE_BRE_DATA_PAGE_LOADED;
    constructor(public payload: any) {}
}

export class HylineLoadBreDataPage implements Action {
    readonly type = HylineActionTypes.HYLINE_LOAD_BRE_DATA_PAGE;
    constructor(public payload: any) {}
}

export class HylineHavDataPageLoaded implements Action {
    readonly type = HylineActionTypes.HYLINE_HAV_DATA_PAGE_LOADED;
    constructor(public payload: any) {}
}

export class HylineLoadHavDataPage implements Action {
    readonly type = HylineActionTypes.HYLINE_LOAD_HAV_DATA_PAGE;
    constructor(public payload: any) {}
}

export class HylineGetTypes implements Action {
    readonly type = HylineActionTypes.HYLINE_GET_TYPES
    constructor(public payload: any) {}
}

export class HylineTypesLoaded implements Action {
    readonly type = HylineActionTypes.HYLINE_TYPES_LOADED;
    constructor(public payload: any) {}
}

export class HylineGetDataTypes implements Action {
    readonly type = HylineActionTypes.HYLINE_GET_DATA_TYPES
    constructor(public payload: any) {}
}

export class HylineDataTypesLoaded implements Action {
    readonly type = HylineActionTypes.HYLINE_DATA_TYPES_LOADED;
    constructor(public payload: any) {}
}

export type HylineActions =
                | HylineAdd
                | HylineEdit
                | HylineGetPage
                | HylinePageLoaded
                | HylineAdded
                | HylineDeleted
                | HylineDelete 
                | HylineEdited
                | HylineGetAll
                | HylineAllLoaded
                | HylineLoadDataPage
                | HylineDataPageLoaded
                | HylineGetTypes
                | HylineTypesLoaded
                | HylineGetDataTypes
                | HylineDataTypesLoaded
                | HylineLoadHavDataPage
                | HylineLoadBreDataPage
                | HylineHavDataPageLoaded
                | HylineBreDataPageLoaded;