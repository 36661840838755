import { Action } from '@ngrx/store';
import { QueryParamsModel } from '../../_base/crud';
import { Pavilion } from '../_models/pavilion.model';

export enum PavilionActionTypes {
    PAV_EDIT = '[Pav] PavilionEdit',
    PAV_EDITED = '[Pav] PavilionEdited',
    PAV_ADD = '[Pav] PavilionAdd',
    PAV_ADDED = '[Pav] PavilionAdded',
    PAV_DELETE = '[Pav] PavilionDelete',
    PAV_DELETED = '[Pav] PavilionDeleted',
    PAV_GET_PAGE = '[Pav] PavilionGetPage',
    PAV_PAGE_LOADED = '[Pav] PavilionPageLoaded',
    PAV_ASSIGN = '[Pav] PavilionAssign',
    PAV_ASSIGNED = '[Pav] PavilionAssigned',
    PAV_GET_ALL = '[Pav] PavilionGetAll',
    PAV_ALL_LOADED = '[Pav] PavilionAllLoaded',
    PAV_GET_TYPES = '[Pav] PavilionGetTypes',
    PAV_TYPES_LOADED = '[Pav] PavilionTypesLoaded',
    PAV_PAVILIONER_GET_PAGE = '[Pav] PavilionerGetPage',
    PAV_PAVILIONER_PAGE_LOADED = '[Pav] PavilionerPageLoaded',
    PAV_PAVILIONER_GET_ALL = '[Pav] PavilionerGetAll',
    PAV_PAVILIONER_ALL_LOADED = '[Pav] PavilionerAllLoaded',
    PAV_GET_FLOORS = '[Pav] PavilionGetFloors',
    PAV_FLOORS_LOADED = '[Pav] PavilionFloorsLoaded',
    PAV_UNLINK = '[Pav] PavilionUnlink',
    PAV_UNLINKED = '[Pav] PavilionUnlinked'
}

export class PavilionAdd implements Action {
    readonly type = PavilionActionTypes.PAV_ADD;
    constructor(public payload: any) {}
}

export class PavilionEdit implements Action {
    readonly type = PavilionActionTypes.PAV_EDIT;
    constructor(public payload: any) {}
}

export class PavilionGetPage implements Action {
    readonly type = PavilionActionTypes.PAV_GET_PAGE;
    constructor(public payload: {page: QueryParamsModel}) {}
}

export class PavilionPageLoaded implements Action {
    readonly type = PavilionActionTypes.PAV_PAGE_LOADED;
    constructor(public payload: {pavilions: Pavilion[], totalCount:number, page: QueryParamsModel}) {}
}

export class PavilionAdded implements Action {
    readonly type = PavilionActionTypes.PAV_ADDED;
    constructor(public payload: any) {}
}

export class PavilionEdited implements Action {
    readonly type = PavilionActionTypes.PAV_EDITED;
    constructor(public payload: any) {}
}

export class PavilionDelete implements Action {
    readonly type = PavilionActionTypes.PAV_DELETE;
    constructor(public payload: any) {}
}

export class PavilionDeleted implements Action {
    readonly type = PavilionActionTypes.PAV_DELETED;
    constructor(public payload: any) {}
}

export class PavilionGetAll implements Action {
    readonly type = PavilionActionTypes.PAV_GET_ALL;
    constructor(public payload: any) {}
}

export class PavilionAllLoaded implements Action {
    readonly type = PavilionActionTypes.PAV_ALL_LOADED;
    constructor(public payload: any) {}
}

export class PavilionAssign implements Action {
    readonly type = PavilionActionTypes.PAV_ASSIGN;
    constructor(public payload: any) {}
}

export class PavilionAssigned implements Action {
    readonly type = PavilionActionTypes.PAV_ASSIGNED;
    constructor(public payload: any) {}
}
export class PavilionGetTypes implements Action {
    readonly type = PavilionActionTypes.PAV_GET_TYPES;
    constructor(public payload: any) {}
}
export class PavilionTypesLoaded implements Action {
    readonly type = PavilionActionTypes.PAV_TYPES_LOADED;
    constructor(public payload: any) {}
}

export class PavilionerGetPage implements Action {
    readonly type = PavilionActionTypes.PAV_PAVILIONER_GET_PAGE;
    constructor(public payload: any) {}
}

export class PavilionerPageLoaded implements Action {
    readonly type = PavilionActionTypes.PAV_PAVILIONER_PAGE_LOADED;
    constructor(public payload: any) {}
}

export class PavilionUnlink implements Action {
    readonly type = PavilionActionTypes.PAV_UNLINK;
    constructor(public payload: any) {}
}

export class PavilionUnlinked implements Action {
    readonly type = PavilionActionTypes.PAV_UNLINKED;
    constructor(public payload: any) {}
}

export class PavilionerGetAll implements Action {
    readonly type = PavilionActionTypes.PAV_PAVILIONER_GET_ALL;
    constructor(public payload: any) {}
}

export class PavilionerAllLoaded implements Action {
    readonly type = PavilionActionTypes.PAV_PAVILIONER_ALL_LOADED;
    constructor(public payload: any) {}
}

export class PavilionGetFloors implements Action {
    readonly type = PavilionActionTypes.PAV_GET_FLOORS;
    constructor(public payload: any) {}
}

export class PavilionFloorsLoaded implements Action {
    readonly type = PavilionActionTypes.PAV_FLOORS_LOADED;
    constructor(public payload: any) {}
}

export type PavilionActions =
                | PavilionAdd
                | PavilionEdit
                | PavilionGetPage
                | PavilionPageLoaded
                | PavilionAdded
                | PavilionDeleted
                | PavilionDelete 
                | PavilionEdited
                | PavilionAssign
                | PavilionAssigned
                | PavilionGetTypes
                | PavilionTypesLoaded
                | PavilionerGetPage
                | PavilionerPageLoaded
                | PavilionUnlink
                | PavilionUnlinked
                | PavilionerGetAll
                | PavilionerAllLoaded
                | PavilionGetAll
                | PavilionAllLoaded
                | PavilionGetFloors
                | PavilionFloorsLoaded;