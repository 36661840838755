import { Component, Input, OnInit } from '@angular/core';
import { data2Mac1 } from "../../../../../../core/production/_models/chartsData";

@Component({
  selector: 'kt-realtime-mac1-chart3',
  templateUrl: './realtime-mac1-chart3.component.html',
  styleUrls: ['./realtime-mac1-chart3.component.scss']
})
export class RealtimeMac1Chart3Component implements OnInit {
  // var data2Mac1 = [
  //   {
  //     name: "Maquina 1",
  //     series: [
  //       {
  //         name: "00:00:00",
  //         value: 620
  //       },
  //     ]
  //   }
  // ];
  @Input() data: any[];
  view: any[];

  // options
  legend: boolean = false;
  showLabels: boolean = false;
  animations: boolean = false;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = "Hora";
  yAxisLabel: string = "Golpes";
  timeline: boolean = true;

  colorScheme = {
    domain: ["#22bf59"]
  };

  constructor() {
    
  }
  ngOnInit(){
  }

  onSelect(data): void {
    console.log("Item clicked", JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log("Activate", JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log("Deactivate", JSON.parse(JSON.stringify(data)));
  }

  minutesToTimeStr(minutes){
    // let date = new Date();
    const hour = Math.floor(minutes/60);
    const minute = Math.round(minutes%60);
    var time = "";
    if(hour<10){
      time +="0";
    }
    time += `${hour}:`;
    if(minute<10){
      time+="0";
    }
    time += `${minute}`;
    return time;
  }

  dateTickFormatting(val: any): String {
    if (val instanceof Date) {
      // const options = { hour:'numeric', minute:'numeric' };
      // return val.toLocaleDateString("es-ES", options);
      const hour = val.getHours();
      const minute = val.getMinutes();
      var time = "";
      if(hour<10){
        time +="0";
      }
      time += `${hour}:`;
      if(minute<10){
        time+="0";
      }
      time += `${minute}`;
      return time;
    }
  }  
}
