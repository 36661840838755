// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '../../core/core.module';
import { MailModule } from './apps/mail/mail.module';
import { ECommerceModule } from './apps/e-commerce/e-commerce.module';
import { UserManagementModule } from './user-management/user-management.module';
import { MyPageComponent } from './my-page/my-page.component';
import {ProductionModule} from './production/production.module';
// import { EmailTemplateComponent } from './email-template/email-template.component';
// import { PlantConfigurationComponent } from './plant-configuration/plant-configuration.component'


@NgModule({
	declarations: [
		MyPageComponent,
		// EmailTemplateComponent,
		// PlantConfigurationComponent,
		],
	exports: [],
	imports: [
		CommonModule,
		HttpClientModule,
		FormsModule,
		CoreModule,
		PartialsModule,
		MailModule,
		ECommerceModule,
		UserManagementModule,
		//ReportsModule,
		//PlantConfigurationModule,
		//NourishmentModule,
		ProductionModule,
		//BirdTypeModule,
		//BreedingModule,
		//HistorydataModule,
		//VelocityModule,
		//AlarmsModule,
		//GuanocontrolModule
	],
	providers: []
})
export class PagesModule {
}
