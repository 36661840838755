// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import { GrainTypeActions, GrainTypeActionTypes } from '../_actions/grain-stock.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { GrainType } from '../_models/grain-stock.model';

// tslint:disable-next-line:no-empty-interface
export interface GrainTypeState extends EntityState<GrainType> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedGrainTypeId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<GrainType> = createEntityAdapter<GrainType>();

export const initialGrainTypeState: GrainTypeState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery:  new QueryParamsModel({}),
    lastCreatedGrainTypeId: undefined,
    showInitWaitingMessage: true
});

export function grainTypesReducer(state = initialGrainTypeState, action: GrainTypeActions): GrainTypeState {
    switch  (action.type) {
        case GrainTypeActionTypes.GT_ADDED: return adapter.addOne(action.payload.grainType, {
            ...state, lastCreatedGrainTypeId: action.payload.grainType.id
        });
        case GrainTypeActionTypes.GT_EDITED: return adapter.updateOne(action.payload.partialGrainType, state);
        case GrainTypeActionTypes.GT_PAGE_LOADED: return adapter.addMany(action.payload.grainTypes, {
            ...initialGrainTypeState,
            totalCount: action.payload.totalCount,
            lastQuery: action.payload.page,
            listLoading: false,
            showInitWaitingMessage: false
        });
        case GrainTypeActionTypes.GT_ALL_LOADED:
            return adapter.addMany(action.payload.grainTypes, {
                ...initialGrainTypeState,
                listLoading: false,
                showInitWaitingMessage: false
            });
        case GrainTypeActionTypes.GT_DELETED: return adapter.removeOne(action.payload.id, state);

        default: return state;
    }
}

export const getGrainTypeState = createFeatureSelector<GrainTypeState>('grainTypes');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();