import { Action } from '@ngrx/store';

export enum SiloActionTypes {
    SILO_EDIT = '[SILO] SiloEdit',
    SILO_EDITED = '[SILO] SiloEdited',
    SILO_ADD = '[SILO] SiloAdd',
    SILO_ADDED = '[SILO] SiloAdded',
    SILO_DELETE = '[SILO] SiloDelete',
    SILO_DELETED = '[SILO] SiloDeleted',
    SILO_GET_PAGE = '[SILO] SiloGetPage',
    SILO_PAGE_LOADED = '[SILO] SiloPageLoaded',
    SILO_GET_ALL = '[SILO] SiloGetAll',
    SILO_ALL_LOADED = '[SILO] SiloAllloaded'
}

export class SiloAdd implements Action {
    readonly type = SiloActionTypes.SILO_ADD;
    constructor(public payload: any) {}
}

export class SiloEdit implements Action {
    readonly type = SiloActionTypes.SILO_EDIT;
    constructor(public payload: any) {}
}

export class SiloGetPage implements Action {
    readonly type = SiloActionTypes.SILO_GET_PAGE;
    constructor(public payload: any) {}
}

export class SiloPageLoaded implements Action {
    readonly type = SiloActionTypes.SILO_PAGE_LOADED;
    constructor(public payload: any) {}
}

export class SiloAdded implements Action {
    readonly type = SiloActionTypes.SILO_ADDED;
    constructor(public payload: any) {}
}

export class SiloEdited implements Action {
    readonly type = SiloActionTypes.SILO_EDITED;
    constructor(public payload: any) {}
}

export class SiloDelete implements Action {
    readonly type = SiloActionTypes.SILO_DELETE;
    constructor(public payload: any) {}
}

export class SiloDeleted implements Action {
    readonly type = SiloActionTypes.SILO_DELETED;
    constructor(public payload: any) {}
}

export class SiloGetAll implements Action {
    readonly type = SiloActionTypes.SILO_GET_ALL;
    constructor(public payload: any) {}
}

export class SiloAllLoaded implements Action {
    readonly type = SiloActionTypes.SILO_ALL_LOADED;
    constructor(public payload: any) {}
}

export type SiloActions =
                | SiloAdd
                | SiloEdit
                | SiloGetPage
                | SiloPageLoaded
                | SiloAdded
                | SiloDeleted
                | SiloDelete 
                | SiloEdited
                | SiloGetAll
                | SiloAllLoaded;