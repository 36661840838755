// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {EventMqttService} from '../../../core/production/_services/EventMqttService'
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// Translate
import { TranslateModule } from '@ngx-translate/core';
import { PartialsModule } from '../../partials/partials.module';
// Services
import { HttpUtilsService, TypesUtilsService, InterceptService, LayoutUtilsService} from '../../../core/_base/crud';
// Shared
import { 
	ActionNotificationComponent,
	DeleteEntityDialogComponent,
	FetchEntityDialogComponent,
	UpdateStatusDialogComponent
 } from '../../partials/content/crud';

 // Import Charts module
import { NgxChartsModule }from '@swimlane/ngx-charts';
import { GaugeChartModule } from 'angular-gauge-chart'

 // Components
import {RealtimeDashboardComponent} from './realtime/realtime-dashboard/realtime-dashboard.component'
import { HistorycDashboardComponent } from './historyc/historyc-dashboard/historyc-dashboard.component';
import { MacDayDashboardComponent } from './mac-day/mac-day-dashboard/mac-day-dashboard.component';
import { ReportsComponent } from './reports/reports.component'
// Material
import {
	MatInputModule,
	MatPaginatorModule,
	MatProgressSpinnerModule,
	MatSortModule,
	MatTableModule,
	MatSelectModule,
	MatMenuModule,
	MatProgressBarModule,
	MatButtonModule,
	MatCheckboxModule,
	MatDialogModule,
	MatTabsModule,
	MatNativeDateModule,
	MatCardModule,
	MatRadioModule,
	MatIconModule,
	MatDatepickerModule,
	MatExpansionModule,
	MatAutocompleteModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatSnackBarModule,
	MatTooltipModule
} from '@angular/material';
import { ProductionComponent } from './production.component';
import { Mac1Chart1Component } from './charts/line-charts/mac1-chart1/mac1-chart1.component';
import { Mac1Chart2Component } from './charts/line-charts/mac1-chart2/mac1-chart2.component';
import { RealtimeMac1Chart1Component } from './charts/gauge-charts/realtime-mac1-chart1/realtime-mac1-chart1.component';
import { RealtimeMac1Chart2Component } from './charts/gauge-charts/realtime-mac1-chart2/realtime-mac1-chart2.component';
import { RealtimeMac1Chart3Component } from './charts/line-charts/realtime-mac1-chart3/realtime-mac1-chart3.component';
import { MacdayMac1Chart1Component } from './charts/line-charts/macday-mac1-chart1/macday-mac1-chart1.component';
import { MacdayMac1Chart2Component } from './charts/line-charts/macday-mac1-chart2/macday-mac1-chart2.component';
import { assemblersReducer, AssemblerEffects, shiftsReducer, shiftTypesReducer, ShiftEffects, ShiftService, AssemblerService } from '../../../core/plant-configuration';
import { ProductionService } from '../../../core/production';

const routes: Routes = [
	{
		path: '',
		component: ProductionComponent,
		children: [
			{
				path: '',
				redirectTo: 'realtime',
				pathMatch: 'full'
			},
			{
				path: 'realtime/:id',
				component: RealtimeDashboardComponent
			},	
			{
				path: 'realtime',
				component: RealtimeDashboardComponent
			},			
			{
				path: 'mac-day-dashboard',
				component: MacDayDashboardComponent
			},
			{
				path: 'history-production',
				component: HistorycDashboardComponent
			},
			{
				path: 'reports',
				component: ReportsComponent
			}
	  
		]
	}
];

@NgModule({
  imports: [
	CommonModule,
    HttpClientModule,
		PartialsModule,
		RouterModule.forChild(routes),		
		StoreModule.forFeature('assemblers', assemblersReducer),
		EffectsModule.forFeature([AssemblerEffects]),
		StoreModule.forFeature('shifts', shiftsReducer),
		StoreModule.forFeature('shiftTypes', shiftTypesReducer),
		EffectsModule.forFeature([ShiftEffects]),
		FormsModule,
		ReactiveFormsModule,
		TranslateModule.forChild(),
		NgxChartsModule,
		GaugeChartModule,
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
    	MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatExpansionModule,
		MatTabsModule,
		MatTooltipModule,
		MatDialogModule
  ],
  providers: [
		InterceptService,
		{
        	provide: HTTP_INTERCEPTORS,
       	 	useClass: InterceptService,
			multi: true
		},
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				panelClass: 'kt-mat-dialog-container__wrapper',
				height: 'auto',
				width: '900px'
			}
		},
		HttpUtilsService,
		TypesUtilsService,
		LayoutUtilsService,
		EventMqttService,
		AssemblerService,
		ShiftService,
		ProductionService
  ],
  entryComponents: [
		ActionNotificationComponent,
		DeleteEntityDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent
	],
  declarations: [
    ProductionComponent,
    RealtimeDashboardComponent,    
	HistorycDashboardComponent,
	MacDayDashboardComponent,
	ReportsComponent,
	Mac1Chart1Component,
	Mac1Chart2Component,
	RealtimeMac1Chart1Component,
	RealtimeMac1Chart2Component,
	RealtimeMac1Chart3Component,
	MacdayMac1Chart1Component,
	MacdayMac1Chart2Component

],
  
})
export class ProductionModule { }
