// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import { PavilionActions, PavilionActionTypes } from '../_actions/pavilion.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { Floor, Pavilion, Pavilioner, PavilionType } from '../_models/pavilion.model';

// tslint:disable-next-line:no-empty-interface
export interface PavilionState extends EntityState<Pavilion> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedPavId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<Pavilion> = createEntityAdapter<Pavilion>();

export const initialPavilionState: PavilionState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery:  new QueryParamsModel({}),
    lastCreatedPavId: undefined,
    showInitWaitingMessage: true
});

export function pavilionsReducer(state = initialPavilionState, action: PavilionActions): PavilionState {
    switch  (action.type) {
        case PavilionActionTypes.PAV_ADDED: return adapter.addOne(action.payload.pavilion, {
            ...state, lastCreatedPavId: action.payload.pavilion.id
        });
        case PavilionActionTypes.PAV_EDITED: 
            if(action.payload.partialPavilion)
                return adapter.updateOne(action.payload.partialPavilion, state);
            return state;
        case PavilionActionTypes.PAV_DELETED: 
            if(action.payload.id)
                return adapter.removeOne(action.payload.id, state);
            return state;
        // case PavilionActionTypes.PAV_ASSIGNED: return adapter.updateOne(action.payload.pavilion, state);
        case PavilionActionTypes.PAV_PAGE_LOADED: 
            //console.log(JSON.stringify(action));
            return adapter.addMany(action.payload.pavilions, {
                ...initialPavilionState,
                totalCount: action.payload.totalCount,
                lastQuery: action.payload.page,
                listLoading: false,
                showInitWaitingMessage: false
            });
        case PavilionActionTypes.PAV_ALL_LOADED:
            return adapter.addMany(action.payload.pavilions, {
                ...initialPavilionState,
                totalCount: action.payload.pavilions.length,
                listLoading: false,
                showInitWaitingMessage: false
            });
        // case UserActionTypes.UsersPageCancelled: return {
        //     ...state, listLoading: false, lastQuery: new QueryParamsModel({})
        // };
        default: return state;
    }
}

export const getPavilionState = createFeatureSelector<PavilionState>('pavilions');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();

//PAVILION TYPES
export interface PavilionTypeState{
    pavilionTypes:PavilionType[],
    pavilionTypesLoaded:boolean;
}

export const initialPavilionTypeState: PavilionTypeState = {
    pavilionTypes: undefined,
    pavilionTypesLoaded: false
}

export function pavilionTypesReducer(state = initialPavilionTypeState, action: PavilionActions):PavilionTypeState{
    switch(action.type){
        case PavilionActionTypes.PAV_TYPES_LOADED:
            return {
                pavilionTypesLoaded: true,
                pavilionTypes: action.payload.pavilionTypes
            };
        default:
            return state;
    }
}

//PAVILION FLOORS
export interface PavilionFloorsState{
    floors:Floor[],
    floorsLoaded:boolean;
}

export const initialPavilionFloorState: PavilionFloorsState = {
    floors: undefined,
    floorsLoaded: false
}

export function pavilionFloorsReducer(state = initialPavilionFloorState, action: PavilionActions):PavilionFloorsState{
    switch(action.type){
        case PavilionActionTypes.PAV_FLOORS_LOADED:
            return {
                floorsLoaded: true,
                floors: action.payload.floors
            };
        default:
            return state;
    }
}

//PAVILIONERS
export interface PavilionerState extends EntityState<Pavilioner> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedPavilionId: number;
    lastCreatedPartnerId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const pavilionerAdapter: EntityAdapter<Pavilioner> = createEntityAdapter<Pavilioner>();

export const initialPavilionerState: PavilionerState = pavilionerAdapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery:  new QueryParamsModel({}),
    lastCreatedPavilionId: undefined,
    lastCreatedPartnerId: undefined,
    showInitWaitingMessage: true
});

export function pavilionersReducer(state = initialPavilionState, action: PavilionActions): PavilionState {
    switch  (action.type) {
        case PavilionActionTypes.PAV_ASSIGNED: 
            if(action.payload.partialPavilioner)
                return adapter.updateOne(action.payload.partialPavilioner, state);
            return state;
        case PavilionActionTypes.PAV_UNLINKED: 
            if(action.payload.partialPavilioner)
                return adapter.updateOne(action.payload.partialPavilioner, state);
            return state;
        case PavilionActionTypes.PAV_PAVILIONER_PAGE_LOADED: 
            console.log(JSON.stringify(action));
            return adapter.addMany(action.payload.pavilioners, {
                ...initialPavilionState,
                totalCount: action.payload.totalCount,
                lastQuery: action.payload.page,
                listLoading: false,
                showInitWaitingMessage: false
            });
        case PavilionActionTypes.PAV_PAVILIONER_ALL_LOADED:
            return adapter.addMany(action.payload.pavilioners, {
                ...initialPavilionState,
                totalCount: action.payload.pavilioners.length,
                listLoading: false,
                showInitWaitingMessage: false
            });
        default: return state;
    }
}

export const getPavilionerState = createFeatureSelector<PavilionerState>('pavilioners');