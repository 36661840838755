// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { SiloState } from '../_reducers/silo.reducers';
import { Silo } from '../_models/silo.model';

export const selectSilosState = createFeatureSelector<SiloState>('silos');

export const selectSiloById = (siloId: number) => createSelector(
    selectSilosState,
    silosState => silosState.entities[siloId]
);

export const selectSilosPageLoading = createSelector(
    selectSilosState,
    silosState => silosState.listLoading
);

// export const selectProductsActionLoading = createSelector(
//     selectSilosState,
//     customersState => customersState.actionsloading
// );

export const selectSilosPageLastQuery = createSelector(
    selectSilosState,
    silosState => silosState.lastQuery
);

export const selectLastCreatedSiloId = createSelector(
    selectSilosState,
    silosState => silosState.lastCreatedSiloId
);

export const selectSilosInitWaitingMessage = createSelector(
    selectSilosState,
    silosState => silosState.showInitWaitingMessage
);

export const selectSilosInStore = createSelector(
    selectSilosState,
    silosState => {
        const items: Silo[] = [];
        each(silosState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: Silo[] = httpExtension.sortArray(items, silosState.lastQuery.sortField, silosState.lastQuery.sortOrder);
        return new QueryResultsModel(result, silosState.totalCount, '');
    }
);

export const selectHasSilosInStore = createSelector(
    selectSilosInStore,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);
