import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {formatDate } from '@angular/common';
import { interval, of, Subscription } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { AssemblerGetAll, AssemblersDataSource, ShiftService } from '../../../../core/plant-configuration';
import { ProductionService } from '../../../../core/production';
import { AppState } from '../../../../core/reducers';
import { Store } from '@ngrx/store';

@Component({
  selector: 'kt-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  reportData:ReportData[] = DATA;
  amountProDest:number
  amountVelProd:number
  amountTpoEfec:number
  amountTpoDete:number
  amountTpoUsoEst:number

  months = [
    {value : '1', viewValue: 'ENERO'},
    {value : '2', viewValue: 'FEBRERO'},
    {value : '3', viewValue: 'MARZO'},
    {value : '4', viewValue: 'ABRIL'},
    {value : '5', viewValue: 'MAYO'},
    {value : '6', viewValue: 'JUNIO'},
    {value : '7', viewValue: 'JULIO'},
    {value : '8', viewValue: 'AGOSTO'},
    {value : '9', viewValue: 'SEPTIEMBRE'},
    {value : '10', viewValue: 'OCTUBRE'},
    {value : '11', viewValue: 'NOVIEMBRE'},
    {value : '12', viewValue: 'DICIEMBRE'}
  ]

  // years = [
  //   {value : '1', viewValue: '2021'},
  //   {value : '2', viewValue: '2020'},
  //   {value : '3', viewValue: '2019'},
  //   {value : '4', viewValue: '2018'},
  //   {value : '5', viewValue: '2017'},
  //   {value : '6', viewValue: '2016'},
  //   {value : '7', viewValue: '2015'},
  //   {value : '8', viewValue: '2014'},
  //   {value : '9', viewValue: '2013'},
  //   {value : '10', viewValue: '2012'},
  //   {value : '11', viewValue: '2011'},
  //   {value : '12', viewValue: '2010'},
  // ]

  years = [0, 1,2,3,4,5,6,7,8,9,10,11,12].map(num=>{
    const date = new Date();
    const year = date.getFullYear();
    return {
      viewValue: (year - num).toString()
    };
  })

  private subscriptions: Subscription[] = [];
  assemblerDataSource: AssemblersDataSource;
  assemblersResult: any[] = [];
  periodLoaded: boolean = false;

  rts_history_result_data = {
    prom_effectove_use : 0,
    prom_vel : 0.0,
    total_detention_time_min : '00:00',
    total_working_time_min : '00:00',
    total_units : 0,
  }
  productionTime: string;
  stoppedTime: string;
  velocity: number;
  effectiveUse: number;
  units: number;
  selectedMonth: number;
  selectedYear: number;
  plantProductionTime: number;
  plantStoppedTime: number;
  plantVelocity: number;
  plantEffectiveUse: number;
  plantUnits: number;

  constructor(
    private cdr: ChangeDetectorRef,
    private productionService: ProductionService,
    private store: Store<AppState>,
    private shiftService: ShiftService
  ) {
  }
  ngAfterViewInit(): void {
    this.loadAssemblersList();
  }

  ngOnInit() {
    this.subscriptions.push(
      interval(1000*10).pipe(
        startWith(0)
      ).subscribe(()=>{
        document.getElementById("btnfechahora").innerHTML = formatDate(new Date(),'dd/MM/yyyy','en') +' | ' + formatDate(new Date(),'hh:mm','en');
        this.cdr.detectChanges();
      })
    );

    this.assemblerDataSource = new AssemblersDataSource(this.store);

    this.subscriptions.push(
      this.assemblerDataSource.entitySubject.subscribe(res=>{
        if(res){
          res = res.sort((a,b)=> a.pos-b.pos);
          console.log(`res: ${JSON.stringify(res)}`);
          this.assemblersResult = res.map(assembler=>Object.assign({
            productionTime:'00:00',
            stoppedTime:'00:00',
            effectiveUse: 0,
            velocity: 0,
            units: 0
          }, assembler));
        }
        // console.log("assembler list retrieved: "+JSON.stringify(res));
        this.cdr.detectChanges();
      })
    );    
  }

  loadAssemblersList() {
    this.store.dispatch(new AssemblerGetAll({}));
  }

  ngOnDestroy(){
    this.subscriptions.forEach(sub=>sub.unsubscribe());
  }

  changeMonth(month:string){
    this.selectedMonth = parseInt(month);
  }
  
  changeYear(year:string){
    this.selectedYear = parseInt(year);
  }

  searchPeriod(){
    console.log("search period");
    const st = new Date(this.selectedYear, this.selectedMonth-1, 0);
    const et = new Date(this.selectedYear, this.selectedMonth, 0);

    this.plantProductionTime = 0;
    this.plantEffectiveUse = 0;
    this.plantVelocity = 0;
    this.plantUnits = 0;
    this.plantStoppedTime = 0;

    this.assemblersResult.forEach(assembler=>{
      this.subscriptions.push(
        of(null).pipe(
          switchMap(()=>this.productionService.getStatsPerPeriod(assembler, null, st, et))
        ).subscribe(stats=>{
          console.log(`stats for assembler ${assembler.name}: ${JSON.stringify(stats)}`);
          assembler.productionTime = this.minutesToTimeStr(stats.production_time);
          assembler.stoppedTime = this.minutesToTimeStr(stats.detention_time);
          assembler.velocity = stats.velocity?stats.velocity:0;
          assembler.effectiveUse = stats.effective_use?parseFloat(stats.effective_use)*100:0;
          assembler.units = stats.units?stats.units:0;
          this.plantProductionTime += parseInt(stats.production_time?stats.production_time:0)/this.assemblersResult.length;
          this.plantStoppedTime += parseInt(stats.detention_time?stats.detention_time:0)/this.assemblersResult.length;
          this.plantVelocity += parseFloat(assembler.velocity?assembler.velocity:0);
          this.plantEffectiveUse += parseFloat(assembler.effectiveUse?assembler.effectiveUse:0)/this.assemblersResult.length;
          this.plantUnits += parseFloat(assembler.units?assembler.units:0);
          this.cdr.detectChanges();
        })
      );
    })    
  }

  minutesToTimeStr(minutes){
    // let date = new Date();
    const hour = Math.floor(minutes/60);
    const minute = Math.round(minutes%60);
    var time = "";
    if(hour<10){
      time +="0";
    }
    time += `${hour}:`;
    if(minute<10){
      time+="0";
    }
    time += `${minute}`;
    return time;
  }

}

export interface ReportData {
  eNumber: string,
  proDest: number,
  velProd: number,
  tpoEfec: number,
  tpoDete: number,
  tpoUsoEst: number
}

export const DATA = [
  {eNumber: 'E N° 1', proDest: 678.4, velProd: 42, tpoEfec: 88.7, tpoDete: 56.4, tpoUsoEst: 66.5},
  {eNumber: 'E N° 2', proDest: 678.4, velProd: 42, tpoEfec: 88.7, tpoDete: 56.4, tpoUsoEst: 66.5},
  {eNumber: 'E N° 3', proDest: 678.4, velProd: 42, tpoEfec: 88.7, tpoDete: 56.4, tpoUsoEst: 66.5},
  {eNumber: 'E N° 4', proDest: 678.4, velProd: 42, tpoEfec: 88.7, tpoDete: 56.4, tpoUsoEst: 66.5},
  {eNumber: 'E N° 5', proDest: 678.4, velProd: 42, tpoEfec: 88.7, tpoDete: 56.4, tpoUsoEst: 66.5}
] 