import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_SHIFT_URL} from '../../connection.data';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { Shift } from '../_models/shift.model';
//const API_USERS_URL = 'api/users';
//const API_PERMISSION_URL = 'api/permissions';
//const API_ROLES_URL = 'api/roles';

//const BASE_URL = "http://localhost:3000"                //INTERNAL DEV
//const BASE_URL = "http://ir4hub.cl:45016"                //LAB DEV

@Injectable()
export class ShiftService{
    constructor(private http: HttpClient){

    }

    getAll():Observable<Shift[]>{
        const url = `${API_SHIFT_URL}/all`;
        return this.http.get<{rows: any, status: string}>(url).pipe(
            map((response)=>{
                return response.rows;
            })
        )
    }

    getTypes():Observable<Shift[]>{
        const url = `${API_SHIFT_URL}/types`;
        return this.http.get<{rows: any, status: string}>(url).pipe(
            map((response)=>{
                return response.rows;
            })
        )
    }

    getPage(queryParams: QueryParamsModel):Observable<QueryResultsModel>{
        const url = `${API_SHIFT_URL}/page`;
        return this.http.post<QueryResultsModel>(url, queryParams)
    }

    add(shift:Shift):Observable<number>{
        return this.http.post<any>(API_SHIFT_URL, shift).pipe(
            map((response)=> response.id)
        );
    }

    edit(shift:Shift):Observable<string>{
        return this.http.put<any>(`${API_SHIFT_URL}/${shift.id}`, shift).pipe(
            map((response)=>response.status)
        );
    }

    delete(shift:Shift):Observable<string>{
        return this.http.delete<any>(`${API_SHIFT_URL}/${shift.id}`).pipe(
            map((response)=>response.status)
        );
    }

    currentId():Observable<any>{
        const url = `${API_SHIFT_URL}/current`;
        return this.http.get<{shiftId:number, date:string}>(url).pipe(
            map((response)=>{
                return response;
            })
        )
    }
}