export class MenuConfig {
	public defaults: any = {
		header: {
			self: {},
			items: [				
				{
					title: 'Producción Planta',
					root : true,
					alignment: 'left',
					toggle: 'click',
					submenu: [
						{
							title: 'Todas las ensabladoras',
							page: '/production/mac-day-dashboard',							
						},
						{
							title: 'Producción por máquina',
							page: '/production/realtime'
						},						
						{
							title: 'Historicos',
							page: '/production/hystoric',
							submenu : [
								{
									title: 'Por turno y maquina',
									page: '/production/history-production'
								},
								{
									title: 'Reportes mensuales',
									page: '/production/reports'
								}
							]
						},						
					]
				},
				{
					title: 'Configuración Planta',
					root : true,
					alignment: 'left',
					toggle: 'click',
					submenu: [
						{
							title: 'Máquinas',
							page: '/plant-configuration/assemblers'
						},
						{
							title: 'Turnos',
							page: '/plant-configuration/shifts'
						},						
					]
				},
				{
					title: 'Administración',
					root : true,
					alignment: 'left',
					toggle: 'click',
					submenu: [
						{
							title: 'Usuarios',
							page: '/user-management/users'
						},
						{
							title: 'Perfiles',
							page: '/user-management/roles'
						},						
					]
				}
			]
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
						