import { Action } from '@ngrx/store';

export enum ShiftActionTypes {
    SHIFT_EDIT = '[SHIFT] ShiftEdit',
    SHIFT_EDITED = '[SHIFT] ShiftEdited',
    SHIFT_ADD = '[SHIFT] ShiftAdd',
    SHIFT_ADDED = '[SHIFT] ShiftAdded',
    SHIFT_DELETE = '[SHIFT] ShiftDelete',
    SHIFT_DELETED = '[SHIFT] ShiftDeleted',
    SHIFT_GET_PAGE = '[SHIFT] ShiftGetPage',
    SHIFT_PAGE_LOADED = '[SHIFT] ShiftPageLoaded',
    SHIFT_GET_ALL = '[SHIFT] ShiftGetAll',
    SHIFT_ALL_LOADED = '[SHIFT] ShiftAllloaded',
    SHIFT_GET_TYPES = '[SHIFT] ShiftGetTypes',
    SHIFT_TYPES_LOADED = '[SHIFT] ShiftTypesloaded',
}

export class ShiftAdd implements Action {
    readonly type = ShiftActionTypes.SHIFT_ADD;
    constructor(public payload: any) {}
}

export class ShiftEdit implements Action {
    readonly type = ShiftActionTypes.SHIFT_EDIT;
    constructor(public payload: any) {}
}

export class ShiftGetPage implements Action {
    readonly type = ShiftActionTypes.SHIFT_GET_PAGE;
    constructor(public payload: any) {}
}

export class ShiftPageLoaded implements Action {
    readonly type = ShiftActionTypes.SHIFT_PAGE_LOADED;
    constructor(public payload: any) {}
}

export class ShiftGetTypes implements Action {
    readonly type = ShiftActionTypes.SHIFT_GET_TYPES;
    constructor(public payload: any) {}
}

export class ShiftTypesLoaded implements Action {
    readonly type = ShiftActionTypes.SHIFT_TYPES_LOADED;
    constructor(public payload: any) {}
}

export class ShiftAdded implements Action {
    readonly type = ShiftActionTypes.SHIFT_ADDED;
    constructor(public payload: any) {}
}

export class ShiftEdited implements Action {
    readonly type = ShiftActionTypes.SHIFT_EDITED;
    constructor(public payload: any) {}
}

export class ShiftDelete implements Action {
    readonly type = ShiftActionTypes.SHIFT_DELETE;
    constructor(public payload: any) {}
}

export class ShiftDeleted implements Action {
    readonly type = ShiftActionTypes.SHIFT_DELETED;
    constructor(public payload: any) {}
}

export class ShiftGetAll implements Action {
    readonly type = ShiftActionTypes.SHIFT_GET_ALL;
    constructor(public payload: any) {}
}

export class ShiftAllLoaded implements Action {
    readonly type = ShiftActionTypes.SHIFT_ALL_LOADED;
    constructor(public payload: any) {}
}

export type ShiftActions =
                | ShiftAdd
                | ShiftEdit
                | ShiftGetPage
                | ShiftPageLoaded
                | ShiftGetTypes
                | ShiftTypesLoaded
                | ShiftAdded
                | ShiftDeleted
                | ShiftDelete 
                | ShiftEdited
                | ShiftGetAll
                | ShiftAllLoaded;