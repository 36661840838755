// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import { ShiftActions, ShiftActionTypes } from '../_actions/shift.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { Shift, ShiftType } from '../_models/shift.model';

// tslint:disable-next-line:no-empty-interface
export interface ShiftState extends EntityState<Shift> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedShiftId: number;
    lastUpdatedShiftId: number;
    lastDeletedShiftId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<Shift> = createEntityAdapter<Shift>();

export const initialShiftState: ShiftState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery:  new QueryParamsModel({}),
    lastCreatedShiftId: undefined,
    lastUpdatedShiftId: undefined,
    lastDeletedShiftId: undefined,
    showInitWaitingMessage: true
});

export function shiftsReducer(state = initialShiftState, action: ShiftActions): ShiftState {
    switch  (action.type) {
        case ShiftActionTypes.SHIFT_ADDED: return adapter.addOne(action.payload.shift, {
            ...state, lastCreatedShiftId: action.payload.shift.id
        });
        case ShiftActionTypes.SHIFT_EDITED: return adapter.updateOne(action.payload.partialShift, 
            {...state, lastUpdatedShiftId: action.payload.partialShift.id});
        case ShiftActionTypes.SHIFT_PAGE_LOADED: 
            // console.log("reducer: page loaded.. "+JSON.stringify(action));
            return adapter.addMany(action.payload.shifts, {
                ...initialShiftState,
                totalCount: action.payload.totalCount,
                lastQuery: action.payload.page,
                listLoading: false,
                showInitWaitingMessage: false
            });
        case ShiftActionTypes.SHIFT_ALL_LOADED: return adapter.addMany(action.payload.shifts, {
            ...initialShiftState,
            listLoading: false,
            showInitWaitingMessage: false
        });
        case ShiftActionTypes.SHIFT_DELETED: return adapter.removeOne(action.payload.id, 
            {...state, lastDeletedShiftId: action.payload.id});
        // case UserActionTypes.UsersPageCancelled: return {
        //     ...state, listLoading: false, lastQuery: new QueryParamsModel({})
        // };
        default: return state;
    }
}

export const getShiftState = createFeatureSelector<ShiftState>('shifts');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();

//SHIFT TYPES
export interface ShiftTypeState{
    shiftTypes:ShiftType[],
    shiftTypesLoaded:boolean;
}

export const initialShiftTypeState: ShiftTypeState = {
    shiftTypes: undefined,
    shiftTypesLoaded: false
}

export function shiftTypesReducer(state = initialShiftTypeState, action: ShiftActions):ShiftTypeState{
    switch(action.type){
        case ShiftActionTypes.SHIFT_TYPES_LOADED:
            return {
                shiftTypesLoaded: true,
                shiftTypes: action.payload.shiftTypes
            };
        default:
            return state;
    }
}