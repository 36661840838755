import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable} from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import {
    AssemblerActionTypes,
    AssemblerAdd,
    AssemblerAdded,
    AssemblerAllLoaded,
    AssemblerDelete,
    AssemblerDeleted,
    AssemblerEdit,
    AssemblerEdited,
    AssemblerGetAll,
    AssemblerGetPage,
    AssemblerPageLoaded
} from '../_actions/assembler.actions';
import { AssemblerService } from '../_services/assembler.service';


@Injectable()
export class AssemblerEffects {

    constructor(
        private actions: Actions,
        private assemblerService: AssemblerService,
        private router: Router,
    ) {}

    @Effect()
    add: Observable<any> = this.actions.pipe(
        ofType(AssemblerActionTypes.ASSEMBLER_ADD),
        map((action: AssemblerAdd) => action.payload),
        switchMap(payload => {
            return this.assemblerService.add(payload.assembler).pipe(
                map((assemblerId)=>{ //Adecuarse a la response recibida de api real
                    //console.log("add assembler");
                    //console.log(JSON.stringify(payload.assembler));
                    let assembler = {
                        ...payload.assembler,
                        id: assemblerId
                    };
                    return new AssemblerAdded({assembler: assembler});
                })
            );
        })  
    );

    @Effect()
    edit: Observable<any> = this.actions.pipe(
        ofType(AssemblerActionTypes.ASSEMBLER_EDIT),
        map((action: AssemblerEdit) => action.payload),
        switchMap(payload => {
            return this.assemblerService.edit(payload.assembler).pipe(
                map((response)=>{ //Adecuarse a la response recibida de api real
                    //console.log("edit assembler");
                    //console.log(JSON.stringify(payload.assembler));
                    return new AssemblerEdited({partialAssembler: payload.assembler});
                })
            );
        })  
    );

    @Effect()
    delete: Observable<any> = this.actions.pipe(
        ofType(AssemblerActionTypes.ASSEMBLER_DELETE),
        map((action: AssemblerDelete) => action.payload),
        switchMap(payload => {
            return this.assemblerService.delete(payload.assembler).pipe(
                map((response)=>{ //Adecuarse a la response recibida de api real
                    //console.log("delete assembler");
                    //console.log(JSON.stringify(payload.assembler));
                    return new AssemblerDeleted({id: payload.assembler.id});
                })
            );
        })  
    );

    @Effect()
    getPage: Observable<any> = this.actions.pipe(
        ofType(AssemblerActionTypes.ASSEMBLER_GET_PAGE),
        map((action: AssemblerGetPage) => action.payload),
        switchMap(payload => {
            return this.assemblerService.getPage(payload.page).pipe(
                map((response)=>{ //Adecuarse a la response recibida de api real
                    // console.log("get page assembler");
                    // console.log(JSON.stringify(response));
                    return {assemblers: response.items, page: payload.page, totalCount: response.totalCount};
                })
            );
        }),
        map((response)=>{
            return new AssemblerPageLoaded(response);
        })
    );

    @Effect()
    getAll: Observable<any> = this.actions.pipe(
        ofType(AssemblerActionTypes.ASSEMBLER_GET_ALL),
        map((action: AssemblerGetAll) => action.payload),
        switchMap(payload => {            
            return this.assemblerService.getAll().pipe(
                map((assemblers)=>{ //Adecuarse a la response recibida de api real
                    //console.log("get all assemblers");
                    //console.log(JSON.stringify(assemblers));
                    return assemblers
                })
            );
        }),
        map((assemblers)=>{
            return new AssemblerAllLoaded({assemblers: assemblers});
        })
    );

    @Effect({dispatch:false})
    pageLoaded: Observable<any> = this.actions.pipe(
        ofType(AssemblerActionTypes.ASSEMBLER_PAGE_LOADED),
        //tap((action:AssemblerPageLoaded)=>console.log("assembler page loaded"))
    );

    @Effect({dispatch:false})
    allLoaded: Observable<any> = this.actions.pipe(
        ofType(AssemblerActionTypes.ASSEMBLER_ALL_LOADED),
        //tap((action:AssemblerAllLoaded)=>console.log("assembler all loaded"))
    );

    @Effect({dispatch:false})
    added: Observable<any> = this.actions.pipe(
        ofType(AssemblerActionTypes.ASSEMBLER_ADDED),
        //tap((action:AssemblerAdded)=>console.log("assembler added"))
    );

    @Effect({dispatch:false})
    edited: Observable<any> = this.actions.pipe(
        ofType(AssemblerActionTypes.ASSEMBLER_EDITED),
        //tap((action:AssemblerEdited)=>console.log("assembler edited"))
    );

    @Effect({dispatch:false})
    deleted: Observable<any> = this.actions.pipe(
        ofType(AssemblerActionTypes.ASSEMBLER_DELETED),
        //tap((action:AssemblerDeleted)=>console.log("assembler deleted"))
    );
}