import { Action } from '@ngrx/store';

export enum GrainTypeActionTypes {
    GT_ADD = '[GT] GrainTypeAdd',
    GT_EDIT = '[GT] GrainTypeEdit',
    GT_GET_PAGE = '[GT] GrainTypeGetPage',
    GT_DELETE = '[GT] GrainTypeDelete',
    GT_ADDED = '[GT] GrainTypeAdded',
    GT_EDITED = '[GT] GrainTypeEdited',
    GT_DELETED = '[GT] GrainTypeDeleted',
    GT_PAGE_LOADED = '[GT] GrainTypePageLoaded',
    GT_GET_ALL = '[GT] GrainTypeGetAll',
    GT_ALL_LOADED = '[GT] GrainTypeAllLoaded'
}

export class GrainTypeAdd implements Action {
    readonly type = GrainTypeActionTypes.GT_ADD;
    constructor(public payload: any) {}
}

export class GrainTypeEdit implements Action {
    readonly type = GrainTypeActionTypes.GT_EDIT;
    constructor(public payload: any) {}
}

export class GrainTypeDelete implements Action {
    readonly type = GrainTypeActionTypes.GT_DELETE;
    constructor(public payload: any) {}
}

export class GrainTypeGetPage implements Action {
    readonly type = GrainTypeActionTypes.GT_GET_PAGE;
    constructor(public payload: any) {}
}

export class GrainTypeGetAll implements Action {
    readonly type = GrainTypeActionTypes.GT_GET_ALL;
    constructor(public payload: any) {}
}

export class GrainTypeAdded implements Action {
    readonly type = GrainTypeActionTypes.GT_ADDED;
    constructor(public payload: any) {}
}

export class GrainTypeEdited implements Action {
    readonly type = GrainTypeActionTypes.GT_EDITED;
    constructor(public payload: any) {}
}

export class GrainTypeDeleted implements Action {
    readonly type = GrainTypeActionTypes.GT_DELETED;
    constructor(public payload: any) {}
}

export class GrainTypePageLoaded implements Action {
    readonly type = GrainTypeActionTypes.GT_PAGE_LOADED;
    constructor(public payload: any) {}
}

export class GrainTypeAllLoaded implements Action {
    readonly type = GrainTypeActionTypes.GT_ALL_LOADED;
    constructor(public payload: any) {}
}

export type GrainTypeActions =
                | GrainTypeAdd
                | GrainTypeAdded
                | GrainTypeDelete
                | GrainTypeDeleted
                | GrainTypeEdit
                | GrainTypeEdited
                | GrainTypeGetPage
                | GrainTypePageLoaded
                | GrainTypeGetAll
                | GrainTypeAllLoaded;