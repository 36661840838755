import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'kt-realtime-mac1-chart1',
  templateUrl: './realtime-mac1-chart1.component.html',
  styleUrls: ['./realtime-mac1-chart1.component.scss']
})
export class RealtimeMac1Chart1Component implements OnInit, OnChanges {

  public canvasWidth = 250;
  @Input() needleValue:number;
  @Input() title: string;
  @Input() arcDelimiters: number[];

  lastValue:number = 0;
/*   public bottomLabel = `% Velocidad Máxima`;
  public name = "Velocidad Instantánea" */
  public options = {
      hasNeedle: true,
      needleColor: '#3d394f',
      needleUpdateSpeed: 1000,
      arcColors: ['#3d394f', '#f6954b'],
      arcDelimiters: [90],
      rangeLabel: ['0', '100'],
      needleStartValue: 0,
      rangeLabelFontSize: 13,
  }
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.options.arcDelimiters = this.arcDelimiters;
    this.options.needleStartValue = this.lastValue;
    this.lastValue = this.needleValue;
  }

  ngOnInit() {
    this.options.arcDelimiters = this.arcDelimiters;
  }

}
