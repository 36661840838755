import { Component, OnInit } from '@angular/core';
import { data2Mac1 } from "../../../../../../core/production/_models/chartsData";

@Component({
  selector: 'kt-macday-mac1-chart2',
  templateUrl: './macday-mac1-chart2.component.html',
  styleUrls: ['./macday-mac1-chart2.component.scss']
})
export class MacdayMac1Chart2Component implements OnInit {

  data2Mac1: any[];
  view: any[];

  // options
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = "Hora";
  yAxisLabel: string = "Unidades";
  timeline: boolean = true;

  colorScheme = {
    domain: ["#aae3f5"]
  };

  constructor() {
    
  }
  ngOnInit(){
    Object.assign(this, { data2Mac1 });
  }

  onSelect(data): void {
    console.log("Item clicked", JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log("Activate", JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log("Deactivate", JSON.parse(JSON.stringify(data)));
  }


}
