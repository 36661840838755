// RxJS
import { delay, distinctUntilChanged } from 'rxjs/operators';
// CRUD
import { QueryResultsModel, BaseDataSource } from '../../_base/crud';
// State
import { Store, select } from '@ngrx/store';
import { AppState } from '../../reducers';
import { selectHylineBreDatasInitWaitingMessage, selectHylineBreDatasInStore, selectHylineBreDatasPageLoading, selectHylineDatasInitWaitingMessage, selectHylineDatasInStore, selectHylineDatasPageLoading, selectHylineHavDatasInitWaitingMessage, selectHylineHavDatasInStore, selectHylineHavDatasPageLoading, selectHylinesInitWaitingMessage, selectHylinesInStore, selectHylinesPageLoading } from '../_selectors/hyline.selectors';

// Selectors

export class HylinesDataSource extends BaseDataSource {
	constructor(private store: Store<AppState>) {
		super();
		this.loading$ = this.store.pipe(
			select(selectHylinesPageLoading)
		);

		this.isPreloadTextViewed$ = this.store.pipe(
			select(selectHylinesInitWaitingMessage)
		);

		this.store.pipe(
			select(selectHylinesInStore)
		).subscribe((response: QueryResultsModel) => {
			this.paginatorTotalSubject.next(response.totalCount);
			this.entitySubject.next(response.items);
		});
	}
}

export class HylineDatasDataSource extends BaseDataSource {
	constructor(private store: Store<AppState>) {
		super();
		this.loading$ = this.store.pipe(
			select(selectHylineDatasPageLoading)
		);

		this.isPreloadTextViewed$ = this.store.pipe(
			select(selectHylineDatasInitWaitingMessage)
		);

		this.store.pipe(
			select(selectHylineDatasInStore)
		).subscribe((response: QueryResultsModel) => {
			this.paginatorTotalSubject.next(response.totalCount);
			this.entitySubject.next(response.items);
		});
	}
}

export class HylineBreDatasDataSource extends BaseDataSource {
	constructor(private store: Store<AppState>) {
		super();
		this.loading$ = this.store.pipe(
			select(selectHylineBreDatasPageLoading)
		);

		this.isPreloadTextViewed$ = this.store.pipe(
			select(selectHylineBreDatasInitWaitingMessage)
		);

		this.store.pipe(
			select(selectHylineBreDatasInStore)
		).subscribe((response: QueryResultsModel) => {
			this.paginatorTotalSubject.next(response.totalCount);
			this.entitySubject.next(response.items);
		});
	}
}

export class HylineHavDatasDataSource extends BaseDataSource {
	constructor(private store: Store<AppState>) {
		super();
		this.loading$ = this.store.pipe(
			select(selectHylineHavDatasPageLoading)
		);

		this.isPreloadTextViewed$ = this.store.pipe(
			select(selectHylineHavDatasInitWaitingMessage)
		);

		this.store.pipe(
			select(selectHylineHavDatasInStore)
		).subscribe((response: QueryResultsModel) => {
			this.paginatorTotalSubject.next(response.totalCount);
			this.entitySubject.next(response.items);
		});
	}
}