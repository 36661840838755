import { Component, OnInit,NgZone,ViewChild,ChangeDetectionStrategy,ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { ProductionService} from './../../../../../core/production/index';
import { of, Subscription } from 'rxjs';
import { AssemblerGetAll, AssemblersDataSource, Shift, ShiftGetAll, ShiftsDataSource, ShiftService } from '../../../../../core/plant-configuration';
import { AppState } from '../../../../../core/reducers';
import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs/operators';
interface Ciclo {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'kt-historyc-dashboard',
  templateUrl: './historyc-dashboard.component.html',
  styleUrls: ['./historyc-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})


export class HistorycDashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  // @ViewChild(Widget12Component, { static: true }) remoteHistory: Widget12Component;
  // @ViewChild(DeadlyAccHistorycPavilionComponent, { static: true }) remoteHistory1: DeadlyAccHistorycPavilionComponent;
  // @ViewChild(DeadlyHistorycPavilionComponent, { static: true }) remoteHistory2: DeadlyHistorycPavilionComponent;
  // @ViewChild(PerformanceAccHistorycPavilionComponent, { static: true }) remoteHistory3: DeadlyHistorycPavilionComponent;
  // @ViewChild(NourishmentHistorycPavilionComponent, { static: true }) remoteHistory4: NourishmentHistorycPavilionComponent;
 
  // @ViewChild(PavilionHistoryService, { static: true }) remoteHistory5: PavilionHistoryService;


  private subscriptions: Subscription[] = [];
  assemblerDataSource: AssemblersDataSource;
  shiftDataSource: ShiftsDataSource;
  currentShift: Shift;
  shiftsResult: Shift[] = [];
  currentAssembler: any;
  assemblersResult: any[] = [];
  st: Date;
  et: Date;
  periodLoaded: boolean = false;

  rts_history_result_data = {
    prom_effectove_use : 0,
    prom_vel : 0.0,
    total_detention_time_min : '00:00',
    total_working_time_min : '00:00',
    total_units : 0,
  }
  productionTime: string;
  stoppedTime: string;
  velocity: number;
  effectiveUse: number;
  units: number;

  constructor(
    private cdr: ChangeDetectorRef,
    private productionService: ProductionService,
    private store: Store<AppState>,
    private shiftService: ShiftService
  ) {
  }
  ngAfterViewInit(): void {
    this.loadAssemblersList();
    this.loadAllShift();
  }

  ngOnInit() {
    this.shiftDataSource = new ShiftsDataSource(this.store);
    this.assemblerDataSource = new AssemblersDataSource(this.store);
    const entitiesSubscription = this.shiftDataSource.entitySubject.pipe(
    ).subscribe(res => {
      this.shiftsResult = res;
      // console.log("shift list retrieved: "+JSON.stringify(res));
      this.cdr.detectChanges();
    });
    this.subscriptions.push(entitiesSubscription);
    this.loadAllShift();
    const currentDate = new Date();
    this.st = new Date();
    this.st.setDate(currentDate.getDate()-7);
    this.et = currentDate;

    this.subscriptions.push(
      this.assemblerDataSource.entitySubject.subscribe(res=>{
        res = res.sort((a,b)=> a.pos-b.pos);
        this.assemblersResult = res;
        // console.log("assembler list retrieved: "+JSON.stringify(res));
        this.cdr.detectChanges();
      })
    );    
  }

  loadAssemblersList() {
    this.store.dispatch(new AssemblerGetAll({}));
  }

  ngOnDestroy(){
    this.subscriptions.forEach(sub=>sub.unsubscribe());
  }

  loadAllShift(){
    this.store.dispatch(new ShiftGetAll({}));
  }

  findShift(shiftId: number){
    return this.shiftsResult.find(shift=>shift.id==shiftId);
  }

  findAssembler(assemblerId: number){
    return this.assemblersResult.find(assembler=>assembler.id==assemblerId);
  }

  changeShift(shiftId: number){
    this.currentShift = this.findShift(shiftId);
  }

  changeAssembler(assemblerId: number){
    this.currentAssembler = this.findAssembler(assemblerId);
  }

  searchPeriod(){
    console.log("search period");
    this.subscriptions.push(
      of(null).pipe(
        switchMap(()=>this.productionService.getStatsPerPeriod(this.currentAssembler, this.currentShift, this.st, this.et))
      ).subscribe(stats=>{
        this.productionTime = this.minutesToTimeStr(stats.production_time);
        this.stoppedTime = this.minutesToTimeStr(stats.detention_time);
        this.velocity = stats.velocity;
        this.effectiveUse = stats.effective_use;
        this.units = stats.units;
        this.periodLoaded = true;
      })
    );    
  }

  minutesToTimeStr(minutes){
    // let date = new Date();
    const hour = Math.floor(minutes/60);
    const minute = Math.round(minutes%60);
    var time = "";
    if(hour<10){
      time +="0";
    }
    time += `${hour}:`;
    if(minute<10){
      time+="0";
    }
    time += `${minute}`;
    return time;
  }
}
