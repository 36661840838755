// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { ProfilesState } from '../_reducers/profile.reducers';
import * as fromProfile from '../_reducers/profile.reducers';
import { each } from 'lodash';
import { Profiles } from '../_models/profile.model';



export const selectProfilesState = createFeatureSelector<ProfilesState>('profiles');

export const selectProfileById = (profileId: number) => createSelector(
    selectProfilesState,
    profilesState => profilesState.entities[profileId]
);
export const selectAllProfiles = createSelector(
    selectProfilesState,
    fromProfile.selectAll
);

export const selectAllProfilesIds = createSelector(
    selectProfilesState,
    fromProfile.selectIds
);

export const allProfilesLoaded = createSelector(
    selectProfilesState,
    profilesState => profilesState.isAllProfilesLoaded
);

export const selectProfilesPageLoading = createSelector(
    selectProfilesState,
    profilesState => {
        return profilesState.listLoading;
    }
);

export const selectProfilesActionLoading = createSelector(
    selectProfilesState,
    profilesState => profilesState.actionsloading
);

export const selectLastCreatedProfileId = createSelector(
    selectProfilesState,
    profilesState => profilesState.lastCreatedProfileId
);

export const selectProfilesPageLastQuery = createSelector(
    selectProfilesState,
    profilesState => profilesState.lastQuery
);

export const selectProfilesInStore = createSelector(
    selectProfilesState,
    profilesState => {
        const items: Profiles[] = [];
        each(profilesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: Profiles[] = httpExtension.sortArray(items, profilesState.lastQuery.sortField, profilesState.lastQuery.sortOrder);
        return new QueryResultsModel(result, profilesState.totalCount, '');
    }
);

export const selectProfilesShowInitWaitingMessage = createSelector(
    selectProfilesState,
    profilesState => profilesState.showInitWaitingMessage
);

export const selectHasProfilesInStore = createSelector(
    selectProfilesState,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);
