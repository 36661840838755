import { BaseModel } from '../../_base/crud';

export class HistorycData extends BaseModel {
    pavilion_id?: number; 
      starting_date?: any;
      ending_date ?: any;
      starting_week?: any;
      ending_week?:any;
      generation_id?:number;
      clear(){
        this.pavilion_id = 0;
        this.starting_date = '';
        this.ending_date = '';
        this.starting_week = '';
        this.ending_week = '';
        this.generation_id = 0;
      }
}