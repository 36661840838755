import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable} from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import {
    GrainTypeActionTypes,
    GrainTypeAdd,
    GrainTypeAdded,
    GrainTypeAllLoaded,
    GrainTypeDelete,
    GrainTypeDeleted,
    GrainTypeEdit,
    GrainTypeEdited,
    GrainTypeGetAll,
    GrainTypeGetPage,
    GrainTypePageLoaded
} from '../_actions/grain-stock.actions';
import { GrainTypeService } from '../_services/grain-stock.service';


@Injectable()
export class GrainTypeEffects {

    constructor(
        private actions: Actions,
        private grainTypeService: GrainTypeService,
        private router: Router,
    ) {}

    @Effect()
    add: Observable<any> = this.actions.pipe(
        ofType(GrainTypeActionTypes.GT_ADD),
        map((action: GrainTypeAdd) => action.payload),
        switchMap(payload => {
            return this.grainTypeService.add(payload.grainType).pipe(
                map((response)=>{ //Adecuarse a la response recibida de api real
                    console.log("add grain type");
                    console.log(JSON.stringify(payload.grainType));
                    return new GrainTypeAdded({grainType: payload.grainType});
                })
            );
        })  
    );

    @Effect()
    edit: Observable<any> = this.actions.pipe(
        ofType(GrainTypeActionTypes.GT_EDIT),
        map((action: GrainTypeEdit) => action.payload),
        switchMap(payload => {
            return this.grainTypeService.edit(payload.grainType).pipe(
                map((response)=>{ //Adecuarse a la response recibida de api real
                    console.log("edit grain type");
                    console.log(JSON.stringify(payload.grainType));
                    return new GrainTypeEdit({partialGrainType: payload.grainType});
                })
            );
        })  
    );

    @Effect()
    delete: Observable<any> = this.actions.pipe(
        ofType(GrainTypeActionTypes.GT_DELETE),
        map((action: GrainTypeDelete) => action.payload),
        switchMap(payload => {
            return this.grainTypeService.delete(payload.grainType).pipe(
                map((response)=>{ //Adecuarse a la response recibida de api real
                    console.log("delete grain type");
                    console.log(JSON.stringify(payload.grainType));
                    return new GrainTypeDelete({id: payload.grainType.id});
                })
            );
        })  
    );

    @Effect()
    getPage: Observable<any> = this.actions.pipe(
        ofType(GrainTypeActionTypes.GT_GET_PAGE),
        map((action: GrainTypeGetPage) => action.payload),
        switchMap(payload => {
            return this.grainTypeService.getPage(payload.page).pipe(
                map((response)=>{ //Adecuarse a la response recibida de api real
                    console.log("get page grain type");
                    console.log(JSON.stringify(payload.grainType));
                    return {grainTypes: response.items, page: payload.page, totalCount: response.totalCount};
                })
            );
        }),
        map((response)=>{
            return new GrainTypePageLoaded(response);
        })
    );

    @Effect()
    getAll: Observable<any> = this.actions.pipe(
        ofType(GrainTypeActionTypes.GT_GET_ALL),
        map((action: GrainTypeGetAll) => action.payload),
        switchMap(payload => {            
            return this.grainTypeService.getAll().pipe(
                map((grainTypes)=>{ //Adecuarse a la response recibida de api real
                    console.log("get all grain types");
                    console.log(JSON.stringify(grainTypes));
                    return grainTypes;
                })
            );
        }),
        map((grainTypes)=>{
            return new GrainTypeAllLoaded({grainTypes: grainTypes});
        })
    );

    @Effect({dispatch:false})
    pageLoaded: Observable<any> = this.actions.pipe(
        ofType(GrainTypeActionTypes.GT_PAGE_LOADED),
        tap((action:GrainTypePageLoaded)=>console.log("grain type page loaded"))
    );

    @Effect({dispatch:false})
    allLoaded: Observable<any> = this.actions.pipe(
        ofType(GrainTypeActionTypes.GT_ALL_LOADED),
        tap((action:GrainTypeAllLoaded)=>console.log("grain type all loaded"))
    );

    @Effect({dispatch:false})
    added: Observable<any> = this.actions.pipe(
        ofType(GrainTypeActionTypes.GT_ADDED),
        tap((action:GrainTypeAdded)=>console.log("grain type added"))
    );

    @Effect({dispatch:false})
    edited: Observable<any> = this.actions.pipe(
        ofType(GrainTypeActionTypes.GT_EDITED),
        tap((action:GrainTypeEdited)=>console.log("grain type edited"))
    );

    @Effect({dispatch:false})
    deleted: Observable<any> = this.actions.pipe(
        ofType(GrainTypeActionTypes.GT_DELETED),
        tap((action:GrainTypeDeleted)=>console.log("grain type deleted"))
    );
}