import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {API_GRAIN_TYPE_URL} from '../../connection.data';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { GrainType } from '../_models/grain-stock.model';

@Injectable()
export class GrainTypeService{
    constructor(private http: HttpClient){

    }

    getAll():Observable<GrainType[]>{
        const url = `${API_GRAIN_TYPE_URL}/all`;
        return this.http.get<{rows: any, status: string}>(url).pipe(
            map((response)=>{
                return response.rows;
            })
        )
    }

    getPage(queryParams: QueryParamsModel):Observable<QueryResultsModel>{
        const url = `${API_GRAIN_TYPE_URL}/page`;
        return this.http.post<QueryResultsModel>(url, queryParams)
    }

    add(grainType:GrainType):Observable<number>{
        return this.http.post<any>(API_GRAIN_TYPE_URL, grainType).pipe(
            map((response)=> response.id)
        );
    }

    edit(grainType:GrainType):Observable<string>{
        return this.http.put<any>(API_GRAIN_TYPE_URL, grainType).pipe(
            map((response)=>response.status)
        );
    }

    delete(grainType:GrainType):Observable<string>{
        return this.http.delete<any>(`${API_GRAIN_TYPE_URL}/${grainType.id}`).pipe(
            map((response)=>response.status)
        );
    }
}