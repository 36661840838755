import { Component, OnInit } from '@angular/core';
import {PavilionHistoryService} from '../../../../../core/production/_services/pavilionHistory.service'
@Component({
  selector: 'kt-perfomance-historyc-pavilion',
  templateUrl: './perfomance-historyc-pavilion.component.html',
  styleUrls: ['./perfomance-historyc-pavilion.component.scss']
})

export class PerfomanceHistorycPavilionComponent implements OnInit {

  constructor(
    private pavilionHistoryService : PavilionHistoryService
  ) { }

  ngOnInit() {
    this.pavilionHistoryService.get_history_pavilion_harvest_day(8,'2020-11-01','2020-11-01').subscribe(res => {
      console.table(res);
    })

  }

}
