import { BaseModel } from '../../_base/crud';

export class Pavilion extends BaseModel {
    id: number;
    create_uid: number;
    write_uid: number;
    write_date: any;
    create_date: any;
    name: string;
    iiot_code: string;
    silo_ids: any;
    silo_names: any;
    type: string;
    type_id: number;
    pav_group_id : number;
    floor_ids : any;
    // floors: number; // Define la cantidad de pisos (?)
    // responsible_id: number; //Identificador de usuario del responsable
    pavilioners: string;
    company_id: number;
    clear(){
        this.id                 = undefined;
        this.create_uid         = undefined;
        this.write_date         = undefined;
        this.write_uid          = undefined;
        this.create_date        = undefined;
        this.name               = "";
        this.iiot_code          = undefined;
        this.silo_ids            = undefined;
        this.pav_group_id       = undefined;
        // this.floors             = undefined;
        this.silo_names = [];
        // this.responsible_id     = undefined;
        this.pavilioners        = "";
        this.type_id            = undefined;
        this.company_id         = undefined;
    }
}

export class PavilionType{
    id: number;
    company_id: number;
    name: string;
    clear(){
        this.id         = undefined;
        this.name       = "";
        this.company_id = undefined;
    }
}

export class Pavilioner{
    // pavilion_id: number;
    id: number;
    partner_id: number;
    pavilions: string
    name: string;
    identificator: string; // Es el grupo
    // pavilion_name: string;
    // pavilion_type: string;
    // pavilion_type_id: number;
    // pavilion_iiot_code: string;
    clear(){
        this.id             = undefined;
        this.partner_id     = undefined;
        this.pavilions      = "";
        this.identificator  = "";
        this.name           = "";
    }
}

export class Floor{
    id:number;
    name:string;
    clear(){
        this.name   = "";
        this.id     = undefined;
    }
}