import { BaseModel } from '../../_base/crud';

export class Assembler extends BaseModel {
    id: number;
    create_uid: number;
    write_uid: number;
    write_date: any;
    create_date: any;
    name:string;
    pos: number;
    iiot_code:string;
    company_id: number;
    clear(){
        this.id                 = undefined;
        this.create_uid         = undefined;
        this.write_date         = undefined;
        this.write_uid          = undefined;
        this.create_date        = undefined;
        this.company_id         = undefined;
        this.pos                = undefined;
        this.name               = "";
        this.iiot_code          = "";
    }
}