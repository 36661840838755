import { BaseModel } from '../../_base/crud';
import { Address } from './address.model';
import { SocialNetworks } from './social-networks.model';

/*export class User extends BaseModel {
    id: number;
    username: string;
    password: string;
    email: string;
    accessToken: string;
    refreshToken: string;
    roles: number[];
    pic: string;
    fullname: string;
    occupation: string;
	companyName: string;
	phone: string;
    address: Address;
    socialNetworks: SocialNetworks;

    clear(): void {
        this.id = undefined;
        this.username = '';
        this.password = '';
        this.email = '';
        this.roles = [];
        this.fullname = '';
        this.accessToken = 'access-token-' + Math.random();
        this.refreshToken = 'access-token-' + Math.random();
        this.pic = './assets/media/users/default.jpg';
        this.occupation = '';
        this.companyName = '';
        this.phone = '';
        this.address = new Address();
        this.address.clear();
        this.socialNetworks = new SocialNetworks();
        this.socialNetworks.clear();
    }
}*/
export class User extends BaseModel {
    id: number;
    username: string;
    name: string;
    password_crypt : string;
    login: string;
    accessToken: string;
    partner_id : number;
    active : boolean;
    group_id : number;
    group_name :string;
    mobile: string;
    email : string;
    refreshToken: string;
    profiles: number[];
    write_uid;
    write_date;
    create_uid;
    create_date;
    fullname: string;
    company_id: number;
    is_email_report: boolean;
    clear(): void {
        this.id = 0;
        this.write_uid = 0;
        this.create_uid = 0;
        this.create_date = '';
        this.write_date = '';
        this.username = '';
        this.name = '';
        this.login = '';
        this.accessToken = '';
        this.active = true;
        this.partner_id = 0;
        this.group_id = 0;
        this.group_name = '';
        this.email = '';
        this.profiles = [];
        this.password_crypt = '';
        this.refreshToken = 'access-token-' + Math.random();
        this.fullname = "";
        this.company_id = undefined;
        this.is_email_report = undefined;
        this.mobile = "";
    }
}

// export class Group extends BaseModel {
//     id: number;    
//     name: string;
//     write_uid;
//     write_date;
//     create_uid;
//     create_date;
//     company_id: number;
//     clear(): void {
//         this.id = 0;
//         this.write_uid = 0;
//         this.create_uid = 0;
//         this.create_date = '';
//         this.write_date = '';
//         this.name = '';
//         this.company_id = undefined;
//     }
// }