import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_PRODUCTION_URL} from '../../connection.data';
import { Assembler, Shift, ShiftActions } from '../../plant-configuration';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
//const API_USERS_URL = 'api/users';
//const API_PERMISSION_URL = 'api/permissions';
//const API_ROLES_URL = 'api/roles';

//const BASE_URL = "http://localhost:3000"                //INTERNAL DEV
//const BASE_URL = "http://ir4hub.cl:45016"                //LAB DEV

@Injectable()
export class ProductionService{
    constructor(private http: HttpClient){

    }

    getInstantVelocity(assembler:Assembler):Observable<number>{
        const url = `${API_PRODUCTION_URL}/velocity/instant/${assembler.id}`;
        return this.http.get<{rows: any, status: string}>(url).pipe(
            map((response)=>{
                return response.rows[0].velocity;
            })
        )
    }

    getAllInstantVelocity():Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/velocity/instant/all`;
        return this.http.get<{rows: any, status: string}>(url).pipe(
            map((response)=>{
                return response.rows;
            })
        )
    }

    getShiftStatsPerAssembler(shift: Shift):Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/summary/shift?shift=${shift.id}`;
        return this.http.get<{rows: any, status: string}>(url).pipe(
            map((response)=>{
                return response.rows;
            })
        )
    }

    getShiftVelocity(assembler:Assembler, shift:Shift):Observable<number>{
        const url = `${API_PRODUCTION_URL}/velocity/shift?assembler=${assembler.id}&shift=${shift.id}`;
        return this.http.get<any>(url).pipe(
            map((response)=>{
                return parseInt(response.velocity);
            })
        )
    }

    getShiftTotalUnits(assembler:Assembler, shift:Shift):Observable<number>{
        const url = `${API_PRODUCTION_URL}/units/shift?assembler=${assembler.id}&shift=${shift.id}`;
        return this.http.get<any>(url).pipe(
            map((response)=>{
                return parseInt(response.units);
            })
        )
    }

    getShiftHistoryPerMinute(assembler:Assembler, shift:Shift):Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/units/history/shift?assembler=${assembler.id}&shift=${shift.id}`;
        return this.http.get<any>(url).pipe(
            map((response)=>{
                if(response.status=='error'){return []}
                return response.rows
            })
        )
    }

    getStatsPerPeriod(assembler:Assembler, shift:Shift, st: Date, et: Date):Observable<any>{
        var url = `${API_PRODUCTION_URL}/summary/period?assembler=${assembler.id}&st=${encodeURIComponent(formatDate(st))}&et=${encodeURIComponent(formatDate(et))}`;
        if(shift){
            url+=`&shift_type_id=${shift.shift_type_id}`
        }
        return this.http.get<any>(url).pipe(
            map((response)=>{
                if(response.status=='error'){return []}
                return response.rows
            })
        )
    }

    // getPage(queryParams: QueryParamsModel):Observable<QueryResultsModel>{
    //     const url = `${API_PRODUCTION_URL}/page`;
    //     return this.http.post<QueryResultsModel>(url, queryParams)
    // }

    // add(assembler:Assembler):Observable<number>{
    //     return this.http.post<any>(API_PRODUCTION_URL, assembler).pipe(
    //         map((response)=> response.id)
    //     );
    // }

    // edit(assembler:Assembler):Observable<string>{
    //     return this.http.put<any>(`${API_PRODUCTION_URL}/${assembler.id}`, assembler).pipe(
    //         map((response)=>response.status)
    //     );
    // }

    // delete(assembler:Assembler):Observable<string>{
    //     return this.http.delete<any>(`${API_PRODUCTION_URL}/${assembler.id}`).pipe(
    //         map((response)=>response.status)
    //     );
    // }
}

function formatDate(date:Date){
    const year = date.getFullYear();
    const month = numberTo2DigitsString(date.getMonth() +1);
    const day = numberTo2DigitsString(date.getDate());
    const hours = numberTo2DigitsString(date.getHours());
    const minutes = numberTo2DigitsString(date.getMinutes());
    const seconds = numberTo2DigitsString(date.getSeconds());
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

function numberTo2DigitsString(number){
    return `${number<10?0:''}${number}`;
}