// Angular
import { Component, ElementRef, Input, OnInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
// Layout config
import { LayoutConfigService } from '../../../../../core/_base/layout';
import {HistorycData} from '../../../../../core/production/_models/HistorycData.model'
import {PavilionHistoryService} from '../../../../../core/production'
@Component({
  selector: 'kt-pavilion1-historyc',
  templateUrl: './pavilion1-historyc.component.html',
  styleUrls: ['./pavilion1-historyc.component.scss']
})
export class Pavilion1HistorycComponent implements OnInit,OnChanges {

  // Public properties
	@Input() historyc: HistorycData
	@Input() type = 'line';
  	@ViewChild('chart', {static: true}) chart: ElementRef;
  	data: { labels: string[], datasets: any[] };

	/**
	 * Component constructor
	 * @param layoutConfigService
	 */
	constructor(
		private layoutConfigService: LayoutConfigService,
		private pavilionHistoryService : PavilionHistoryService
		) {

	}
	ngOnChanges(changes: SimpleChanges): void {
		//throw new Error('Method not implemented.');
		console.log('Entering Pavilion1HistorycComponent ngOnInit');
		console.table(this.historyc);
		console.log('Ending Pavilion1HistorycComponent ngOnInit');
		if (changes)
			console.table(changes)
		
		const valueChange = changes['historyc'];
		console.log(valueChange);
			
	

	}
	ngOnInit(): void {
	
		if (!this.data) {
			const color = Chart.helpers.color;
			this.data = {
				labels: ['1 Jan', '2 Jan', '3 Jan', '4 Jan', '5 Jan', '6 Jan', '7 Jan'],
				datasets: [
					{
						fill: false,
						// borderWidth: 0,
						//backgroundColor: color(this.layoutConfigService.getConfig('colors.state.brand')).alpha(0.6).rgbString(),
						//borderColor: color(this.layoutConfigService.getConfig('colors.state.brand')).alpha(0).rgbString(),

						pointHoverRadius: 4,
						pointHoverBorderWidth: 12,
						//pointBackgroundColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
						pointBorderColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
						//pointHoverBackgroundColor: this.layoutConfigService.getConfig('colors.state.brand'),
						//pointHoverBorderColor: Chart.helpers.color('#000000').alpha(0.1).rgbString(),

						data: [20, 40, 50, 25, 35, 60, 30]
					},
					{
						fill: false,
						// borderWidth: 0,
						//backgroundColor: color(this.layoutConfigService.getConfig('colors.state.brand')).alpha(0.2).rgbString(),
						//borderColor: color(this.layoutConfigService.getConfig('colors.state.brand')).alpha(0).rgbString(),

						pointHoverRadius: 4,
						pointHoverBorderWidth: 12,
						//pointBackgroundColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
						pointBorderColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
						//pointHoverBackgroundColor: this.layoutConfigService.getConfig('colors.state.brand'),
						//pointHoverBorderColor: Chart.helpers.color('#000000').alpha(0.1).rgbString(),

						data: [25, 45, 55, 30, 40, 65, 35]
					},
					{
						fill: false,
						// borderWidth: 0,
						//backgroundColor: color(this.layoutConfigService.getConfig('colors.state.brand')).alpha(0.2).rgbString(),
						borderColor: color(this.layoutConfigService.getConfig('colors.state.brand')).alpha(0).rgbString(),

						pointHoverRadius: 4,
						pointHoverBorderWidth: 12,
						//pointBackgroundColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
						pointBorderColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
						//pointHoverBackgroundColor: this.layoutConfigService.getConfig('colors.state.brand'),
						//pointHoverBorderColor: Chart.helpers.color('#000000').alpha(0.1).rgbString(),

						data: [30, 50, 60, 35, 45, 70, 40]
					},
					{
						fill: false,
						// borderWidth: 0,
						//backgroundColor: color(this.layoutConfigService.getConfig('colors.state.brand')).alpha(0.2).rgbString(),
						//borderColor: color(this.layoutConfigService.getConfig('colors.state.brand')).alpha(0).rgbString(),

						pointHoverRadius: 4,
						pointHoverBorderWidth: 12,
						//pointBackgroundColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
						pointBorderColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
						//pointHoverBackgroundColor: this.layoutConfigService.getConfig('colors.state.brand'),
						//pointHoverBorderColor: Chart.helpers.color('#000000').alpha(0.1).rgbString(),

						data: [35, 55, 65, 40, 50, 75, 45]
					},
					{
						fill: false,
						// borderWidth: 0,
						//backgroundColor: color(this.layoutConfigService.getConfig('colors.state.brand')).alpha(0.2).rgbString(),
						//borderColor: color(this.layoutConfigService.getConfig('colors.state.brand')).alpha(0).rgbString(),

						pointHoverRadius: 4,
						pointHoverBorderWidth: 12,
						//pointBackgroundColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
						pointBorderColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
						//pointHoverBackgroundColor: this.layoutConfigService.getConfig('colors.state.brand'),
						//pointHoverBorderColor: Chart.helpers.color('#000000').alpha(0.1).rgbString(),

						data: [40, 60, 70, 45, 55, 80, 50]
					},
					{
						fill: false,
						// borderWidth: 0,
						//backgroundColor: color(this.layoutConfigService.getConfig('colors.state.brand')).alpha(0.2).rgbString(),
						//borderColor: color(this.layoutConfigService.getConfig('colors.state.brand')).alpha(0).rgbString(),

						pointHoverRadius: 4,
						pointHoverBorderWidth: 12,
						//pointBackgroundColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
						pointBorderColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
						//pointHoverBackgroundColor: this.layoutConfigService.getConfig('colors.state.brand'),
						//pointHoverBorderColor: Chart.helpers.color('#000000').alpha(0.1).rgbString(),

						data: [0, 30, 55, 90, 99, 30, 10]
					},
					{
						fill: false,
						// borderWidth: 0,
						//backgroundColor: color(this.layoutConfigService.getConfig('colors.state.brand')).alpha(0.2).rgbString(),
						//borderColor: color(this.layoutConfigService.getConfig('colors.state.brand')).alpha(0).rgbString(),

						pointHoverRadius: 4,
						pointHoverBorderWidth: 12,
						//pointBackgroundColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
						pointBorderColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
						//pointHoverBackgroundColor: this.layoutConfigService.getConfig('colors.state.brand'),
						//pointHoverBorderColor: Chart.helpers.color('#000000').alpha(0.1).rgbString(),

						data: [20, 30, 40, 50, 60, 70, 80]
					}
				]
			};
		}
		this.initChart();
	}
	private OnChanges(){

	}

	private initChart() {
		// For more information about the chartjs, visit this link
		// https://www.chartjs.org/docs/latest/getting-started/usage.html

		const chart = new Chart(this.chart.nativeElement, {
			type: this.type,
			data: this.data,
			options: {
				responsive: true,
				maintainAspectRatio: false,
				legend: false,
				scales: {
					xAxes: [{
						categoryPercentage: 0.35,
						barPercentage: 0.70,
						display: true,
						scaleLabel: {
							display: false,
							labelString: 'Month'
						},
						gridLines: false,
						ticks: {
							display: true,
							beginAtZero: true,
							fontColor: this.layoutConfigService.getConfig('colors.base.shape.3'),
							fontSize: 13,
							padding: 10
						}
					}],
					yAxes: [{
						categoryPercentage: 0.35,
						barPercentage: 0.70,
						display: true,
						scaleLabel: {
							display: false,
							labelString: 'Value'
						},
						gridLines: {
							color: this.layoutConfigService.getConfig('colors.base.shape.2'),
							drawBorder: false,
							offsetGridLines: false,
							drawTicks: false,
							borderDash: [3, 4],
							zeroLineWidth: 1,
							zeroLineColor: this.layoutConfigService.getConfig('colors.base.shape.2'),
							zeroLineBorderDash: [3, 4]
						},
						ticks: {
							max: 100,
							stepSize: 10,
							display: true,
							beginAtZero: true,
							fontColor: this.layoutConfigService.getConfig('colors.base.shape.3'),
							fontSize: 13,
							padding: 10
						}
					}]
				},
				title: {
					display: false
				},
				hover: {
					mode: 'index'
				},
				tooltips: {
					enabled: true,
					intersect: false,
					mode: 'nearest',
					bodySpacing: 5,
					yPadding: 10,
					xPadding: 10,
					caretPadding: 0,
					displayColors: false,
					backgroundColor: this.layoutConfigService.getConfig('colors.state.brand'),
					titleFontColor: '#ffffff',
					cornerRadius: 4,
					footerSpacing: 0,
					titleSpacing: 0
				},
				layout: {
					padding: {
						left: 0,
						right: 0,
						top: 5,
						bottom: 5
					}
				}
			}
		});
	}

}
