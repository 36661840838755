import { BaseModel } from '../../_base/crud';

export class BirdStock extends BaseModel {
    id: number;
    create_uid: number;
    write_uid: number;
    write_date: any;
    create_date: any;
    company_id: number;
    floor_id: number;
    floor_name: string;
    pavilion_id: number;
    pavilion_name: string;
    pavilion_type: string;
    pavilion_type_id: number;
    bird_type_id: number;
    bird_type: string;
    generation_id: number;
    generation_name: string;
    bird_amount: number;
    c_bird_amount: number; // current bird amount
    age: number; // current age
    grain_type: string;
    grain_type_id: number;
    clear(){
        this.id                 = undefined;
        this.create_uid         = undefined;
        this.write_date         = undefined;
        this.write_uid          = undefined;
        this.create_date        = undefined;
        this.company_id         = undefined;
        this.floor_id           = undefined;
        this.floor_name         = "";
        this.pavilion_id        = undefined;
        this.pavilion_name      = "";
        // this.bird_name          = "";
        this.generation_id      = undefined;
        this.generation_name    = "";
        this.bird_type_id       = undefined;
        this.bird_type          = "";
        // this.egg_type_id        = undefined;
        // this.egg_type           = "";
        this.pavilion_type_id   = undefined;
        this.bird_amount        = undefined;
        this.c_bird_amount      = undefined;
        this.age                = undefined;
        this.grain_type         = "";
        this.grain_type_id      = undefined;
    }
}

export class BreedingStock extends BaseModel {
    id: number;
    create_uid: number;
    write_uid: number;
    write_date: any;
    create_date: any;
    company_id: number;
    pavilion_id: number;
    pavilion_name: string;
    pavilion_type: string;
    pavilion_type_id: number;
    bird_type_id: number;
    bird_type: string;
    breeding_id: number;
    generation_id: number;
    generation_name: string;
    bird_amount: number;
    c_bird_amount: number; // current bird amount
    age_in_weeks: number; // current age
    clear(){
        this.id                 = undefined;
        this.create_uid         = undefined;
        this.write_date         = undefined;
        this.write_uid          = undefined;
        this.create_date        = undefined;
        this.company_id         = undefined;
        this.pavilion_id        = undefined;
        this.pavilion_name      = "";
        // this.bird_name          = "";
        this.generation_id      = undefined;
        this.generation_name    = "";
        this.bird_type_id       = undefined;
        this.bird_type          = "";
        // this.egg_type_id        = undefined;
        // this.egg_type           = "";
        this.pavilion_type_id   = undefined;
        this.bird_amount        = undefined;
        this.c_bird_amount      = undefined;
        this.age_in_weeks       = undefined;
        this.breeding_id        = undefined;
    }
}