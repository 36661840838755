// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
// Auth
import { AuthGuard } from './core/auth';

const routes: Routes = [
	{path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule)},

	{
		path: '',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			
			{
				path: 'user-management',
				loadChildren: () => import('./views/pages/user-management/user-management.module').then(m => m.UserManagementModule)
			},			
			{
				path: 'production',
				loadChildren: () => import('./views/pages/production/production.module').then(m => m.ProductionModule)
			},			
			{
				path: 'alarms',
				loadChildren: () => import('./views/pages/alarms/alarms.module').then(m => m.AlarmsModule)
			},			
			{
				path: 'plant-configuration',
				loadChildren: () => import('./views/pages/plant-configuration/plant-configuration.module').then(m => m.PlantConfigurationModule)
			},			
			{
				path: 'error/403',
				component: ErrorPageComponent,
				data: {
					type: 'error-v6',
					code: 403,
					title: '403... Access forbidden',
					desc: 'Parece que usted no tiene permiso para acceder a la página solicitada.<br> Por favor, contacte al administrador'
				}
			},
			{path: 'error/:type', component: ErrorPageComponent},			
			{path: '', redirectTo: 'production/mac-day-dashboard', pathMatch: 'full'},
			{path: '**', redirectTo: 'production/mac-day-dashboard', pathMatch: 'full'}
		]
	},

	{path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
